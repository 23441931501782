import React from 'react';
import Header from '../component/header';
import { Link } from 'react-router-dom';
import {Navigation} from "../component/navigation/navigation";
import './dashboard.css';

const Dashboard = () => {
    const navigation = Navigation();
    
    return (
        <div className='dashboard'>
            <Header>
                <div className='dashboard-container'>
                    <div className='row' style={{ width: 100 + "%", display: "flex", flexWrap: 'wrap', gap: 16 + 'px', justifyContent: "space-evenly" }}>
                        {navigation.map((nav, index) => (
                          <>
                          {
                            nav.text !== "Dashboard"  ? 
                            <Link to={nav.nav} className='dashboard-item' key={index}>
                            <div className='icon'>{nav.icon}</div>
                            <div className='text'>{nav.text}</div>
                        </Link>
                            : <></>
                          
                          }
                          </>
                        ))}
                    </div>
                </div>
            </Header>
        </div>
    );
}

export default Dashboard;
