import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    height: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
  tableHeader: {
    backgroundColor: '#e0e0e0',
    fontWeight: 'bold',
  },
});

const PDFTable = ({ headers, data, rowHeaders }) => {
  const colWidth = 100 / headers.length + "%";
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeader]}>
      {rowHeaders?.length > 0 ?  <View style={[styles.tableCol, { width: '12%' }]}>
          <Text style={styles.tableCell}></Text>
        </View> : null}
        {headers.map((header, index) => (
          <View key={index} style={[styles.tableCol, { width: colWidth }]}>
            <Text style={styles.tableCell}>{header}</Text>
          </View>
        ))}
      </View>
      {data.map((rowData, rowIndex) => (
        <View key={rowIndex} style={styles.tableRow}>
          {rowHeaders?.length > 0 ? <View style={[styles.tableCol, { width: '12%' }]}>
            <Text style={styles.tableCell}>{rowHeaders[rowIndex]}</Text>
          </View> :  null}
          {rowData.map((cellData, cellIndex) => (
            <View key={cellIndex} style={[styles.tableCol, { width: colWidth }]}>
              <Text style={styles.tableCell}>{cellData}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

export default PDFTable;
