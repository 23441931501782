import React, {  Suspense, useEffect } from 'react';
import Login from './routes/login.js';
import "./App.css";
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import Dashboard from './routes/dashboard.js';
import Create from './routes/template/create.js';
import MultiFormCreator from './routes/multiform.js';
import DraftList from './routes/template/draftList.js';
import Draft from './routes/template/draft.js';
import CertificateList from './routes/template/templates.js';
import Staff from './routes/staff.js';
import Activity from "./routes/activity.js";
import Approval from './routes/template/approval.js';
import Preloader from './component/Preloader';
import Profile from './routes/profile.js';
import StaffList from './routes/staffList.js';
import Token from "./routes/reset/token.js"
import ConfirmEmail from './routes/reset/confirmEmail.js';
import NewPassword from "./routes/reset/newpass.js";
import { useStateValue } from "./state/stateprovider";
import CSVUploader from "./routes/template/bulk.js";
import instance from "./axios";

function App() {
  const [{resetEmail}, dispatch] = useStateValue(); 
  const navigate = useNavigate();


  useEffect(() => {
    const level = window.localStorage.getItem("level");
    const authid = window.localStorage.getItem("authid");
    dispatch({type:"LEVEL", level: level });
    dispatch({type:"AUTH", authid: authid });
    if(resetEmail !== "" && resetEmail !== null && authid === null){
      return;
    }else{
      instance({
        method: "GET",
        headers: { "Content-Type": "application/json" },
        url: `/loginstatus?authid=${authid}`
      }).then(response => {
        if (response.data.status === "invalid") {
          window.localStorage.clear();
          navigate("/", {replace: true});
        }else{
          navigate("/dashboard", {replace: true});
        }
  
      }).catch(err => {
        if(err.response?.data?.status === "invalid"){
          window.localStorage.clear();
           navigate("/", {replace: true});
        }else{
         console.log("an error has occured")
        }
      });
    }

  }, [])
  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
      <Route path="/bulk-certifiate" element={<CSVUploader />} />
      <Route path="/reset-password/password" element={<NewPassword />} />
      <Route path="/reset-password/email" element={<ConfirmEmail />} />
      <Route path="/reset-password/token" element={<Token />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/approval' element={<Approval />} />
      <Route path='/staff/list' element={<StaffList />} />
        <Route path='/staff/activity' element={<Activity />} />
        <Route path='/templates' element={<CertificateList />} />
        <Route path='/staff/create' element={<Staff />} />
        <Route path='/draft/:collectionid' element={<Draft />} />
        <Route path='/draft' element={<DraftList />} />
        <Route path='/multi-form' element={<MultiFormCreator />} />
        <Route path='/create-template' element={<Create />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/' element={<Login />} />
      </Routes>
      </Suspense>
  );
}

export default App;
