import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import './profile.css';
import Header from "../component/header";
import profileImage from "../asset/image/profile.jpg";
import instance from "../axios";
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from '../component/Preloader';
import {useStateValue} from "../state/stateprovider";


const Profile = () => {
  const [{authid}] = useStateValue();
  const Swal = swal;
  // const authid = window.localStorage.getItem("authid");
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [newImage, setNewImage] = useState({});
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassord] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setprocessing] = useState(false);
  const maxFileSize = 10 * 1024 * 1024;


  useEffect(() => {
    instance({
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url: `/staff/profile/view?authid=${authid}`
    }).then(response => {
      if (response.data.status === "success") {
        const profileObject = response.data.result?.image;
        const signatureObject = response.data.result?.signature;
        const profileImage = typeof profileObject === "string" ? JSON.parse(profileObject) : profileObject;
        const signatureImage = typeof signatureObject === "string" ? JSON.parse(signatureObject) : signatureObject;
        setProfile({
          firstName: response.data.result.first_name,
          lastName: response.data.result.last_name,
          email: response.data.result.email,
          signature: signatureImage?.fileBuffer,
          profileImage: profileImage?.fileBuffer,
          role: response.data.result.rolename
        })
        setLoading(false);
      } else if (response.data.status === "invalid") {
        Swal.fire({
          icon: "error",
          title: "you are not authenticated"
        }).then(() => {
          window.localStorage.clear();
          navigate("/", {replace: true});
        })
      } else {
        Swal.fire({
          icon: "error",
          title: "An error occurred"
        });
      }
    }).catch(err => {
      Swal.fire({
        icon: "error",
        title: "An error occurred"
      });
    })
  }, []);
  const [show, setShow] = useState(false);
  const [editField, setEditField] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (field) => {
    setEditField(field);
    setShow(true);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setprocessing(true);
    setLoading(true);
    if (newPassword === confirmPassword) {
      instance({
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        data: { authid: authid, updateType: Object.keys(newImage).length === 0 && oldPassword !== "" ? "password" : "image", oldPassword: oldPassword, newPassword: newPassword, image: newImage },
        url: '/staff/profile/update'
      }).then(response => {
        setprocessing(false);
        setLoading(false);
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            title: response.data.message
          })
        }
      }).catch(err => {
        Swal.fire({
          icon: "error",
          title: "Ane error occurred. Try again later"
        })
      })
    } else {
      Swal.fire({
        icon: "info",
        title: "passowrd doesn't match"
      });
      setLoading(false);
      setprocessing(false)
    }
  if(!processing){
    handleClose();
  }
  };

  return (
    <Header>
      {
        loading ? <Preloader /> :
          <Container className="mt-5">
            <Row>
              <Col md={4}>
                <div className="text-center">
                  <img src={profile.profileImage ? profile.profileImage : profileImage} alt="Profile" className="img-thumbnail" />
                  <Button variant="link" onClick={() => handleShow('profileImage')}><FontAwesomeIcon icon="fa-solid fa-pencil" /></Button>
                </div>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    <div className="profile-info">
                      <h5>First Name</h5>
                      <p>{profile.firstName}</p>
                    </div>

                  </Col>
                  <Col md={6}>
                    <div className="profile-info">
                      <h5>Last Name</h5>
                      <p>{profile.lastName} </p>

                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="profile-info">

                      <h5>Email</h5>
                      <p>{profile.email}</p>
                    </div>
                  </Col>
                      <Col md={6}>
                    <div className="profile-info">

                      <h5>Role</h5>
                      <p>{profile.role}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="profile-info">
                      <h5>Signature</h5>
                      {
                        profile.signature ? 
                        <img src={profile.signature ? profile.signature : ""} alt="signature" className="signature-img-thumbnail" />
                        :
                        <p className="signature-img-thumbnail">No Signature</p>
                      }
                      
                    </div>
                  </Col>
                </Row>


                <Button variant="secondary" className="mt-2" onClick={() => handleShow('password')}>Update Password</Button>
              </Col>
            </Row>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Edit {editField}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  {editField === 'profileImage' ? (
                    <Form.Group controlId="formFile">
                      <Form.Label>Change Profile Image</Form.Label>
                      <Form.Control type="file" onChange={(e) => {
                        const file = e.target.files[0];
                        if (file?.size < maxFileSize) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            setProfile({ ...profile, profileImage: reader.result });
                            setNewImage({ fileBuffer: reader.result, fileExtension: file.type.split("/")[1] })
                          };
                          reader.readAsDataURL(file);
                        } else if (file?.size > maxFileSize) {
                          Swal.fire({
                            icon: "error",
                            text: "Image size should be less than 10MB"
                          });
                        }
                      }} />
                    </Form.Group>
                  ) : (
                    <Form.Group controlId={`form${editField}`}>
                      <Form.Label>{editField}</Form.Label>
                      <Form.Control
                        type="password"
                        name={editField}
                        onChange={e => setOldPassword(e.target.value)}
                        placeholder={`Enter ${editField}`}
                      />
                    </Form.Group>
                  )}

                  {editField === 'password' && (
                    <>
                      <Form.Group controlId="formNewPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="newPassword"
                          onChange={e => setNewPassord(e.target.value)}
                          placeholder="Enter new password"
                        />
                      </Form.Group>
                      <Form.Group controlId="formConfirmPassword">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          onChange={e => setConfirmPassword(e.target.value)}
                          placeholder="Confirm new password"
                        />
                      </Form.Group>
                    </>
                  )}

                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Container>
      }
    </Header>
  );
};

export default Profile;
