import React, { useState, useEffect } from 'react';
import "./header.css";
import instance from "../axios";
import { useNavigate, Link } from 'react-router-dom';
import swal from 'sweetalert2';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MinSidebar from './minSidebar';
import { Navigation } from './navigation/navigation';
import { useStateValue } from '../state/stateprovider';

function Header({ children }) {
    const [{level, authid}] = useStateValue();
    const Swal = swal;
    const navigate = useNavigate();
   // const authid = window.localStorage.getItem("authid");
   // const level = window.localStorage.getItem("level");
    const [showLinks, setShowLinks] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const navigation = Navigation();
    const [profile, setProfile] = useState({});
  
    useEffect(() => {
        instance({
          method: "GET",
          headers: { "Content-Type": "application/json" },
          url: `/staff/profile/view?authid=${authid}`
        }).then(response => {
          if (response.data.status === "success") {
            const profileObject = response.data.result?.image;
            const profileImage = typeof profileObject === "string" ? JSON.parse(profileObject) : profileObject;
            setProfile({
              firstName: response.data.result.first_name,
              lastName: response.data.result.last_name,
              email: response.data.result.email,
              profileImage: profileImage?.fileBuffer,
              role: response.data.result.rolename
            })
          } else if (response.data.status === "invalid") {
            Swal.fire({
              icon: "error",
              title: "you are not authenticated"
            }).then(() => {
              window.localStorage.clear();
              navigate("/", {replace: true});
            })
          } else {
            Swal.fire({
              icon: "error",
              title: "An error occurred"
            });
          }
        }).catch(err => {
          Swal.fire({
            icon: "error",
            title: "An error occurred"
          });
        })
      }, []);

    const logout = () => {
        Swal.fire({
            icon: 'warning',
            title: "Are you sure you want to log out?",
            confirmButtonText: "Yes",
            denyButtonText: "No",
            showDenyButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                instance({
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    data: { authid: authid },
                    url: '/logout'
                }).then(response => {
                    if (response.data.status === "success") {
                        window.localStorage.clear();
                        Swal.fire({
                            icon: "success",
                            title: "Logged Out",
                            text: "You have been successfully logged out.",
                            timer: 2000, // Add a delay before navigating
                            showConfirmButton: false
                        }).then(() => {
                            navigate("/", { replace: true });
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Logout Failed",
                            text: "Something went wrong. Please try again."
                        }).then(() => {
                            navigate("/", { replace: true });
                        });
                    }
                }).catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: "Logout Error",
                        text: "An unexpected error occurred. Please try again later."
                    }).then(() => {
                        navigate("/", { replace: true });
                    });
                });
            } else {
                Swal.close();
            }
        });
    };
    

    return (
        <header className='header'>
            <div className="header_content">
                <div className="header-sidebar">
                {
                    showSideBar ? <Sidebar navigation={navigation} level={level} /> : 
                    <MinSidebar navigation={navigation} level={level} />
                }
                    
                </div>
                <div className="header_section">
                    <div className='header-row' style={{paddingLeft: "20px"}}>
                    {
                        !showSideBar ? <div className="sidebar_button">
                        <FontAwesomeIcon icon="fa-solid fa-bars-staggered" onClick={() => setShowSideBar(true)} />
                        </div>  

                    :            <div className="sidebar_button"> <FontAwesomeIcon icon="fa-solid fa-bars-staggered" onClick={() => setShowSideBar(false)} /></div>
                    }
                        <div className='header-nav'>
                            <div className="links-body">
                             <img src={profile.profileImage} alt="" />
                                 {profile?.firstName && <>
                                    <span className="greeting">Hello {profile?.firstName} {"  "}
                                    {
                                        showLinks ?   <FontAwesomeIcon icon="fa-solid fa-caret-up" className="svg-caret" onClick={() => setShowLinks(false)} /> :
                                        <FontAwesomeIcon icon="fa-solid fa-caret-down" className="svg-caret" onClick={() => setShowLinks(true)} />
                                    } 
                                    </span> 
                                   
                                    </>
                                    }
                            </div>
                            {
                                showLinks ?
                                    <div className='header-links'>
                                        <Link to="/profile" className="profile-link">Profile</Link>
                                        <span onClick={logout} className="logout-btn">Logout</span>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    <div className='header-children'>
                        {children}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
