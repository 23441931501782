import React, { useEffect, useState } from 'react';
import Header from '../../component/header';
import instance from "../../axios"
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {Preview} from "../../component/preview";
import {downloadPdf} from '../../controller/index';
import TableComp from '../../component/table/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from "../../component/Preloader";
import { useStateValue } from "../../state/stateprovider";


const CertificateList = () => {
    const [{ previewState, authid }, dispatch] = useStateValue();
    const Swal = swal;
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [certificateInfo, setCertificateInfo] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [certificateArrange, setCertificateArrange] = useState("individual");
    const [grouped, setGrouped] = useState([]);


    useEffect(() => {
        instance({
            method: "GET",
            headers: { "Content-Type": "application/json" },
            url: `/certificate?authid=${authid}&status=completed`
        }).then(response => {
            setLoading(false);
            if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/", {replace: true});
            } else if (response.data.status === "success"){
                setList(response.data.result);
            }else{
                Swal.fire({
                    icon: "info",
                    text: response.data.message,
                })
            }
        }).catch(err => {
            setLoading(false);
            Swal.fire({
                icon: "error",
                text: err
            }).then(() => {
                navigate("/dashboard");
            })
        });

    }, []);


    useEffect(() => {
        if(certificateArrange === "group"){
            const combine = Array.from(new Set(list?.map(num => num.collectionid))).map(collectionid => {
                const entry = list.find(item => item.collectionid === collectionid);
                return entry;
            });
            setGrouped(combine);
        }else{
            setList(list);
        }
    }, [certificateArrange]);


    const handleCertificateRequest = (certificate_no, type) => {
        setLoading(true);
        instance({
            method: "GET",
            headers: { "Content-Type": "application/json" },
            url: certificateArrange === "individual" ? `/certificate/${certificate_no}?authid=${authid}&status=completed` : `/certificate?collectionid=${certificate_no}&authid=${authid}&status=completed`
        }).then(response => {
            setLoading(false);
            if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/", {replace: true});
            } else if (response.data.status === "success") {
                if(type === "preview"){
                    setCertificateInfo(response.data.result);
                    dispatch({
                        type: "CERT_PREVIEW",
                        previewState: true
                    });
                }else{
                    downloadPdf(response.data.result, "completed");
                }
            }else{
                Swal.fire({
                    icon: "info",
                    text: response.data.message,
                })
            }
        }).catch(err => {
            setLoading(false);
            Swal.fire({
                icon: "error",
                text: err,
            })
        });
    }
    const handleNewCertificate = (certificate_no) => {
        navigate(`/create-template?certificate_no=${certificate_no}`)
    }
   
    return <> {
        previewState
            ?
            <Preview type="completed" certificate={certificateInfo} /> :
            <div className='draftList'>
                <Header>
                    {
                        loading ?
                            <Preloader /> : <>
                              {
                                list.length === 0 ? "no certificates yet" :     <div className='container' >
                                <div className="row">
                                    <div className="col-12">
                                        <h3 style={{ textTransform: "capitalize" }}>Certificate Template</h3>
                                    </div>
                                </div>
                                <div className="row">
                                            <div className="col-6">
                                                <select
                                                    className="form-select w-50 mb-2"
                                                    name="certificate"
                                                    value={certificateArrange}
                                                    onChange={e => setCertificateArrange(e.target.value)}
                                                >
                                                    <option value="individual">Individual</option>
                                                    <option value="group">Group</option>
                                                </select>
                                            </div>
                            </div>
                                        <div className='row'>
                                            {
                                                certificateArrange === "group" ? 
                                                <TableComp title="Certificates" data={grouped.map((item, index) => {
                                                    return {
                                                        "S/N": index + 1, "Company Name": item.company_name, "Issuing Engineer" : item.creatorfirst_name + " " + item.creatorlastname, "Approving Engineer" : item.reviewerfirst_name + " " + item.reviewerlastname, "Created" : item.dateadded.split("T")[0],   "Action": (<>
                                                         
                                                            <FontAwesomeIcon onClick={() => handleCertificateRequest(item.collectionid, "preview")} icon="fa-solid fa-eye" />
                                                            {"   "}
                                                            <FontAwesomeIcon onClick={() => handleCertificateRequest(item.collectionid, "download")} icon="fa-solid fa-download" />
                                                        </>
                                                        )
                                                    }
                                                })} />
                                                    :     <TableComp title="Certificates" data={list.map((item, index) => {
                                                        return {
                                                            "S/N": index + 1, "Company Name": item.company_name, "Certificate Number": item.certificate_no, "Model Number": item.instrument_id.instrumentmodel, "Serial Number": item.instrument_id.instrumentserial, "Description": item.instrument_id.instrumentdescription, "Action": (<> <FontAwesomeIcon className='mx-2' icon="fa-solid fa-pencil" onClick={() => handleNewCertificate(item.certificate_no)} />
                                                                {"  "}
                                                                <FontAwesomeIcon onClick={() => handleCertificateRequest(item.certificate_no, "preview")} icon="fa-solid fa-eye" />
                                                                {"  "}
                                                                <FontAwesomeIcon onClick={() => handleCertificateRequest(item.certificate_no, "download")} icon="fa-solid fa-download" />
                                                            </>
                                                            )
                                                        }
                                                    })} /> 
                                                    
                                            }
                                        </div>
                                    </div>
                              }
                            </>
                    }
                </Header>
            </div>

    }</>
}

export default CertificateList;

