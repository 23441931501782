import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';  // Import the CSS file for styling

const Sidebar = ({ navigation }) => {
    return (
        <div className='sidebar'>
        <div className='header-logo'>
        <img src="/image/logo-2.png" alt="logo" className='logo' />
    </div>
            <div className='sidebar-navigation'>
                {navigation.map((nav, index) => (
                        <Link to={nav.nav} className='sidebar-item' key={index}>
                            <div className='icon'>{nav.icon}</div>
                            <div className='text'>{nav.text}</div>
                        </Link>
                ))}
            </div>
        </div>
    );
}

export default Sidebar;
