import React, { useState, useEffect } from 'react';
import Header from "../component/header";
import instance from "../axios";
import swal from 'sweetalert2';
import { useNavigate, Link } from 'react-router-dom';
import "../asset/css/style.css";
import { useStateValue } from '../state/stateprovider';


const Staff = () => {
  const [{level, authid}] = useStateValue();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [staffCode, setStaffCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signature, setSignature] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectRole, setSelectRole] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true); 
  const navigate = useNavigate();
  const Swal = swal;
  // const authid = window.localStorage.getItem("authid");
  const maxFileSize = 10 * 1024 * 1024;
  const [processing, setProcessing] = useState(false);
  // const level = window.localStorage.getItem("level");

  useEffect(() => {
    if(level === 'admin' || level === 'moderator'){
    // Fetch roles
        instance({
          method: "GET",
          headers: { "Content-Type": "application/json" },
          url: `/staff/roles?authid=${authid}`
        }).then(response => {
          if (response.data.status === "success") {
            setRoles(response.data.result);
          } else if (response.data.status === "invalid") {
            window.localStorage.clear();
            navigate("/", {replace: true});
          }else{
            Swal.fire({
              icon: "info",
              title: response.data.message,
            });
          }
        }).catch(err => {
          Swal.fire({
            icon: "error",
            title: "An error occurred"
          });
        });
    }else{
      navigate("/dashboard", {replace: true});
    }
  }, []);


  // Function to read a file as Base64
  function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (event) => {
        const bString = reader.result;
        resolve(bString);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  const createStaff = async (e) => {
    e.preventDefault();
    setProcessing(true);
    // Validate password match
    if (password !== confirmPassword) {
      setPasswordMatch(false);
      setProcessing(false);
      return;
    }

    // Validate file sizes
    if (profileImage?.size > maxFileSize || signature?.size > maxFileSize) {
      Swal.fire({
        icon: "error",
        text: profileImage?.size > maxFileSize ? "Profile image size should be less than 10MB" : "Signature image size should be less than 10MB"
      });
      setProcessing(false);
      return;
    }

    // Ensure signature is attached
    if (!signature) {
      Swal.fire({
        icon: "error",
        text: "Please attach the staff's signature"
      });
      setProcessing(false)
      return;
    }

    // Prepare profile and signature images
    let profilePic = {};
    let signaturePic = {
      fileBuffer: await readFileAsBase64(signature),
      fileExtension: signature.type.split("/")[1]
    };

    if (profileImage) {
      profilePic = {
        fileBuffer: await readFileAsBase64(profileImage),
        fileExtension: profileImage.type.split("/")[1]
      };
    }

    // Create staff
    instance({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        password: password.trim(),
        staffCode: staffCode.trim(),
        signatureImage: signaturePic,
        profilePicture: profilePic,
        role: selectRole,
        authid: authid
      },
      url: "/staff/add"
    }).then(response => {
      setProcessing(false);
      if (response.data.status === "success") {
        Swal.fire({
          icon: "success",
          text: "Staff created successfully"
        });
        // Clear form fields on success
        setLastName("");
        setFirstName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setProfileImage(null);
        setSignature(null);
        setStaffCode("");
      } else if (response.data.status === "invalid") {
        window.localStorage.clear();
        navigate("/", {replace: true});
      } else {
        Swal.fire({
          icon: "error",
          text: "Staff creation failed. Please try again later."
        });
      }
    }).catch(err => {
      setProcessing(false);
      Swal.fire({
        icon: "error",
        text: "Staff creation failed. Please try again later."
      });
    });
  };

  return (
    <div className="staff">
      <Header>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 style={{ textTransform: "capitalize" }}>create new staff</h3>
            </div>
            <div className="col-2">
            <small className='breadcrumbs'>
            <Link to="/staff/list">Staff List</Link> - Create Staff
            </small> 
          </div>
            <div className="col-lg-12">
              <form className="card" onSubmit={createStaff}>
                <div className="row">

                  <div className="col-6">
                    <div className="card-body">
                      <div className="mb-3">
                        <label htmlFor="staffLastName" className="form-label">Enter Staff Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="staffLastName"
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="staffFirstName" className="form-label">Enter Staff First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="staffFirstName"
                          value={firstName}
                          onChange={e => setFirstName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="staffEmail" className="form-label">Staff Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="staffEmail"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="staffCode" className="form-label">Staff Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="staffCode"
                          value={staffCode}
                          onChange={e => setStaffCode(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="staffRole" className="form-label">Staff Role</label>
                        <select
                          className="form-select"
                          onChange={e => setSelectRole(e.target.value)}
                          value={selectRole}
                          required
                        >
                          <option value="">Assign a role</option>
                          {roles.map((role, index) => (
                            <option key={index} value={role.id}>{role.rolename}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card-body">
                      <div className="mb-3">
                        <label htmlFor="staffImage" className="form-label">Staff Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="staffImage"
                          onChange={e => setProfileImage(e.target.files[0])}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="staffSignature" className="form-label">Staff Signature</label>
                        <input
                          type="file"
                          className="form-control"
                          id="staffSignature"
                          onChange={e => setSignature(e.target.files[0])}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="staffPassword" className="form-label">Staff Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="staffPassword"
                          onChange={e => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="staffConfirmPassword" className="form-label">Confirm Staff Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="staffConfirmPassword"
                          onChange={e => setConfirmPassword(e.target.value)}
                          required
                        />
                        {!passwordMatch && <p className="text-danger">Passwords do not match.</p>}
                      </div>
                      <div className="mb-3">
                      <label htmlFor="submitButton"  style={{visibility: "hidden"}} className="form-label">submit button</label>
                        <button type="submit" disabled={processing} className="form-control btn btn-primary">{processing ? "processing" : "Submit"}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Header>
    </div>
  );
}

export default Staff;
