import React, { useEffect, useState }  from 'react';
import CreateFunc from "../../component/createFunc"
import instance from "../../axios";
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {useStateValue} from "../../state/stateprovider";


const Create = () => {
    const [{authid}] = useStateValue();
    const Swal = swal;
    const urlParams = new URLSearchParams(window.location.search);
    const certificate_no = urlParams.get('certificate_no');
    const navigate = useNavigate();
    const [certData, setCertData] = useState([]);

    useEffect(() => {
        if(certificate_no !== null){
            instance({
                method: "GET",
                headers: { "Content-Type": "application/json" },
                url: `/certificate/${certificate_no}?authid=${authid}&status=completed`
            }).then(response => {
                if (response.data.status === "invalid") {
                    window.localStorage.clear();
                    navigate("/");
                } else if (response.data.status === "success") {
                    setCertData(response.data.result)
                }
            }).catch(err => {
                Swal.fire({
                    icon: "error",
                    text: err.response?.data?.message,
                })
            });
        }
    },[]);
    return <CreateFunc type={certData?.length > 0 ? "modify" : "create"} data={certData?.length > 0 ? certData : []} />
}

export default Create;