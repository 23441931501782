import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { PdfDocument} from "../component/preview";
import QRCode from 'qrcode';

const protocol = window.location.protocol;
const hostname = window.location.host;
const urlWithParams = `${protocol}//${hostname}/main.html`;


export const downloadPdf = async (certificate, type) => {
    const instance = pdf(<PdfDocument type={type} certificate={certificate}  />);

    const asPdfBlob = await instance.toBlob();
    saveAs(asPdfBlob, `${certificate[0].company_name}.pdf`);
};

export const QrCodeGenerator = async (certificate_no) => {
    return new Promise((resolve, reject) => {
        QRCode.toDataURL(`${urlWithParams}?certificateNumber=${certificate_no}`)
            .then(imageURL => {
                return resolve(imageURL);
            })
            .catch(err => {
                reject(err);
            });
    })
}