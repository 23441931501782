import {
    useEffect,
    useRef,
    ChangeEvent,
    ClipboardEvent,
    KeyboardEvent,
    FocusEvent,
  } from "react";
  
  
  function Input({
    index,
    value,
    onChange,
    onPaste,
    onBackspace,
    isFocused,
    onFocus,
    isDisabled,
  }) {
    const ref = useRef(null);
  
    useEffect(() => {
      requestAnimationFrame(() => {
        if (ref.current !== document.activeElement && isFocused) {
          ref.current?.focus();
        }
      });
    }, [isFocused]);
  
    function handleChange(e) {
      onChange(index, e.target.value);
    }
  
    function handlePaste(e) {
      const pastedData = [];
      pastedData.push(e.clipboardData.getData("text"));
      onPaste(pastedData);
    }

    // function handlePaste(e) {
    //   e.preventDefault();
    //   const clipboardData = e.clipboardData //  || window.clipboardData;
    //   const pastedDataPromises = [];
    
    //   for (let i = 0; i < clipboardData.items.length; i++) {
    //     if (clipboardData.items[i].kind === 'string') {
    //       pastedDataPromises.push(new Promise(resolve => {
    //         clipboardData.items[i].getAsString(resolve);
    //       }));
    //     }
    //   }
    
    //   Promise.all(pastedDataPromises).then(pastedData => {
    //     onPaste(pastedData);
    //   });
    // }
    
  
    function handleKeyDown(e) {
      if (e.key === "Backspace") {
        onBackspace();
      }
    }
  
    function handleFocus(e) {
      e.target.setSelectionRange(0, 1);
      onFocus(index);
    }
  
    return (
      <input
        ref={ref}
        type="text"
        value={value}
        onChange={handleChange}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        maxLength={1}
        onFocus={handleFocus}
        disabled={isDisabled}
      />
    );
  }
  
  export default Input;