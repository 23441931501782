import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import instance from "../axios";
import swal from 'sweetalert2';
import loginBanner from "../asset/image/login-banner.png"
import { useStateValue } from '../state/stateprovider';

function Login() {
  const [{}, dispatch] = useStateValue();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const Swal = swal;
  const navigate = useNavigate();


  const LoginForm = async (e) => {
    e.preventDefault();
    setProcessing(true);
    if(email !== "" || password !== ""){
      try {
        const response = await instance({
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: { email: email.trim(), password: password.trim() },
          url: `/login`
        });
        setProcessing(false);
        setEmail("");
        setPassword("");
        switch (response.data.status) {
          case "success":
            window.localStorage.setItem("authid", response.data.result.authid);
            window.localStorage.setItem("level", response.data.result.level);
            dispatch({
              type: "LEVEL",
              level: response.data.result.level,
            });
            dispatch({
              type: "AUTH",
              authid: response.data.result.authid,
            });
            navigate("/dashboard");
            break;
          case "noaccount":
            Swal.fire({
              title: "You don't have an account with us",
              button: "Ok"
            });
            break;
          case "wrongpassword":
            Swal.fire({
              icon: "error",
              title: "You entered a wrong password",
              button: "Ok"
            });
            break;
          case "duplicate_login":
            Swal.fire({
              icon: "info",
              title: "Duplicate Login",
              button: "Ok"
            });
            break;
            case "deactiviated":
              Swal.fire({
                icon: "info",
                title: response.data.message,
                button: "Ok"
              });
              break;
          default:
            Swal.fire({
              icon: "info",
              title: response.data.message,
              button: "Ok"
            });
            break;
        }
  
      } catch (err) {
        setProcessing(false);
        setEmail("");
        setPassword("");
        if (err.response) {
          Swal.fire({
            icon: "error",
            title: err.response.data.message,
            button: "Ok"
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "An error occurred"
          });
        }
      }
    }else{
      Swal.fire({
        icon: "info",
        title: "Please enter a valid email and password",
        button: "Ok"
      });
    }

  };


  return (
    <div className="login">
      <div className="container login-container">
        <div className="row">
        <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-4 d-none d-lg-block p-0">
            {/* Space for image */}
            <div className="image-container">
              <img src={loginBanner} alt="Login" className="login-image" />
            </div>
          </div>
          <div className="col-sm-12 col-lg-6 d-lg-block p-0">
            <form className="loginForm" onSubmit={LoginForm}>
              <div className="mb-3">
                <label htmlFor="inputEmail" className="form-label"><b>Email address</b></label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  style={{height: 60 + "px"}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inputPassword" className="form-label"><b>Password</b></label>
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  id="inputPassword"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  style={{height: 60 + "px"}}
                />
              </div>
              <button type="submit" disabled={processing} style={processing ? {cursor: "not-allowed", fontWeight: 600} : {cursor: "pointer", fontWeight: 600}} className="btn btn-primary mb-3">{processing ? "Processing" : "Log in"}</button>
              <span style={{cursor: "pointer", color:"#630013"}} onClick={() => navigate("/reset-password/email")}>Forgot password?</span>
            </form>
          </div>
          <div className="col-lg-1 d-none d-lg-block"></div>
        </div>
      </div>
    </div>
  );
}

export default Login;
