import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const MinSidebar = ({ navigation }) => {

    return (
        <div className='minsidebar'>
            <div className='header-logo'>
                <img src="/image/logo-2.png" alt="logo" className='min-logo' />
            </div>
            <div className='sidebar-navigation'>
                {navigation.map((nav, index) => (
                        <Link to={nav.nav} className='sidebar-item' key={index}>
                            <div className={`icon-${nav.tooltip}`}>{nav.icon}</div>
                            <Tooltip anchorSelect={`.icon-${nav.tooltip}`} place="top">
                                {nav.text}
                            </Tooltip>
                        </Link>
                ))}
            </div>
        </div>
    );
}

export default MinSidebar;
