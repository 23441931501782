import ReactTableUI from 'react-table-ui'
import { useMemo, useRef } from 'react';


const TableComp = ({title, data}) => {
  // Provide data for the table
  const processedData = data;

  // Create an instance ref that will hold the reference to React Table instance.
  const tableInstanceRef = useRef(null);


  return (
    <ReactTableUI
      title={title}
      data={processedData}
      paginationOptions={{ pageSizes: [10, 20, 50, 100]}}
    //   rowSelectOptions={{selectSubRows: false, disableRowSelect: true}}
      tableInstanceRef={tableInstanceRef}
      style={{
        overflowX: 'auto',  // Enable horizontal scrolling
        maxWidth: '100%',   // Ensure it fits within the container
      }}
    />
    
  )
}

export default TableComp;