import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import instance from "../../axios";
import swal from 'sweetalert2';
import { useStateValue } from '../../state/stateprovider';

function NewPassword() {
  const [{resetEmail}, dispatch] = useStateValue();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const Swal = swal;
  const navigate = useNavigate();
  const authid = window.localStorage.getItem("authid");

  useEffect(() => {
    if(resetEmail !== "" && authid === null){
      return;
    }
    navigate("/", {replace: true});
  }, []);

  const handleForm = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
        if(password === confirmPassword && password?.length > 1){
            const response = await instance({
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { email:resetEmail, password: password.trim() },
                url: `/reset/password`
              });
              setProcessing(false);
              switch (response.data.status) {
                case "success":
                  dispatch({type:"RESET_PASS", resetEmail: "" })
                  Swal.fire({
                    icon: "success",
                    title: response.data.message,
                    button: "Ok"
                  }).then(() => {
                   navigate("/", {replace: true});
                  });
                  break;
                case "invalid_parameter":
                  Swal.fire({
                    title: response.data.message,
                    button: "Ok"
                  });
                  break;
                case "error":
                  Swal.fire({
                    icon: "error",
                    title: "An error has occured. Try later",
                    button: "Ok"
                  });
                  break;
                default:
                  Swal.fire({
                    icon: "info",
                    title: response.data.message,
                    button: "Ok"
                  });
                  break;
              }
        }else{
          setProcessing(false);
            Swal.fire({
                icon: "error",
                title: "Password doesn't match",
                button: "Ok"
              });
        }
    } catch (err) {
      setProcessing(false);
        if(err.response?.data?.status){
            Swal.fire({
              icon: "error",
              title: err.response.data.message,
              button: "Ok"
            });
        }else{
            Swal.fire({
                icon: "error",
                title: "An error occurred"
              });
        }
    }
  };


  return (
      <div className="container" style={{height: 100 + "vh"}}>
        <div className="row" style={{ margin: "2.5rem"}}>
        <div className='col-sm-12 col-lg-12 mb-3'>
            <h3>Reset Your Password</h3>
        </div>
          <div className="col-sm-12 col-lg-12 d-lg-block">
            <form className="loginForm" onSubmit={handleForm}>
              <div className="mb-3">
                <label htmlFor="inputPassword" className="form-label"><b>New Password</b></label>
                <input
                  type="password"
                  className="form-control"
                  id="inputPassword"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{height: 60 + "px", width: 70 + "%"}}
                />
              </div>
              <div className="mb-3">
              <label htmlFor="inputPassword" className="form-label"><b>Confirm Password</b></label>
              <input
                type="password"
                className="form-control"
                id="inputPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{height: 60 + "px", width: 70 + "%"}}
              />
            </div>
              <div className="mb-3">
              <button type="submit" disabled={processing} style={processing ? {cursor: "not-allowed", fontWeight: 600, width: "30% !important"} : {cursor: "pointer", fontWeight: 600, width: "30% !important"}} className="btn btn-primary">{processing ? "Processing" : "Submit"}</button>
                </div>
            </form>
          </div>
        </div>
      </div>
  );
}

export default NewPassword;
