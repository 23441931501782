import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { useStateValue } from '../../state/stateprovider';

export const Navigation = () => {
    // const level = window.localStorage.getItem("level");
    const [{level}] = useStateValue();
    const baseNavigation = [
        {
            text: "Dashboard",
            nav: "/dashboard",
            icon: "fa-solid fa-house",
            tooltip: "Dashboard"
        },
        {
            text: "Create Certificate",
            nav: "/create-template",
            icon: "fa-solid fa-plus",
            tooltip: "certificate"
        },
        {
            text: "Upload Bulk Certificate",
            nav: "/bulk-certifiate",
            icon: "fa-solid fa-file-arrow-up",
            tooltip: "bulk_certificate"
        },
        {
            text: "View Template",
            nav: "/templates",
            icon: "fa-solid fa-eye",
            tooltip: "templates"
        },
        {
            text: "Drafts",
            nav: "/draft",
            icon: "fa-solid fa-pen-to-square",
            tooltip: "drafts"
        },
        (level === "admin" || level === "moderator") && {
            text: "Staff",
            nav: "/staff/list",
            icon: "fa-solid fa-user-plus",
             tooltip: "staff"
        },
        level === "admin" && {
            text: "User Activity",
            nav: "/staff/activity",
            icon: "fa-solid fa-chart-line",
             tooltip: "activities"
        },
        (level === "admin" || level === 'moderator' || level === 'technical') && {
            text: "Draft for Approval",
            nav: "/approval",
            icon: "fa-solid fa-check-circle",
            tooltip: "approval"
        }
    ];
    
    // Filter out any `false` values from the array
    return baseNavigation.filter(Boolean).map(item => ({
        ...item,
        icon: <FontAwesomeIcon icon={item.icon} />
    }));
}