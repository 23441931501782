import React, { useEffect, useState } from 'react';
import Header from '../../component/header';
import instance from "../../axios";
import swal from 'sweetalert2';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableComp from '../../component/table/table';
import Preloader from "../../component/Preloader";
import {useStateValue} from "../../state/stateprovider";
import {Preview} from "../../component/preview";
import {  Form, Button, Modal } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';


const DraftList = () => {
    const [{previewState, authid}, dispatch] = useStateValue();
    const Swal = swal;
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [certificateInfo, setCertificateInfo] = useState([]);
    const [show, setShow] = useState(false);
    const [handleApprovalEngineerChange, setHandleApprovalEngineerChange] = useState(null);
    const [engineerList, setEngineerList] = useState([]);
    const [collectionId, setCollectionId] = useState("");


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        instance({
            method: "GET",
            headers: { "Content-Type": "application/json" },
            url: `/draft/?authid=${authid}`
        }).then(response => {
            setLoading(false);
            if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/", {replace: true});
            } else if (response.data.status === "success") {
                const combine = Array.from(new Set(response.data.result?.map(num => num.collectionid))).map(collectionid => {
                    const entry = response.data.result.find(item => item.collectionid === collectionid);
                    return { collectionid: entry.collectionid, company_name: entry.company_name };
                });
                setList(combine);
            }else{
                Swal.fire({
                    icon: "info",
                    text: response.data.message
                })
            }
        }).catch(err => {
            setLoading(false);
            Swal.fire({
                icon: "error",
                text: err
            }).then(() => {
                navigate("/dashboard");
            })
        });

        instance({
            method: "GET",
            headers: { "Content-Type": "application/json" },
            url: `/engineerlist?authid=${authid}&type=approval`
        }).then(response => {
            if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/");
            } else {
                setEngineerList(response.data.result)
            }
        }).catch(err => {
            Swal.fire({
                icon: "error",
                text: err
            })
        });
    }, []);

    const handleDraftDelete = async (e, collectionid) => {
        e.preventDefault();
        setProcessing(true);
        try {
            const response = await instance.delete("/draft/trash", {
               data: { authid: authid,
                collectionId: collectionid
                }
            });
            setProcessing(false);
            if (response.data.status === "success") {
                Swal.fire({
                    icon: "success",
                    text: "successfully deleted certificate draft(s)"
                }).then(() => {
                    window.location.reload();
                    navigate("/draft", {replace: true});
                })
            } else if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/", { replace: true });
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Couldn't  create delete draft(s)"
                });
            }
        } catch (err) {
            setProcessing(false);
            Swal.fire({
                icon: "error",
                text: "An error occurred"
            });
        }
    }

    const handleCertificateRequest = (certificate_no) => {
        setLoading(true);
        instance({
            method: "GET",
            headers: { "Content-Type": "application/json" },
            url: `/certificate?collectionid=${certificate_no}&authid=${authid}`
        }).then(response => {
            setLoading(false);
            if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/", {replace: true});
            } else if (response.data.status === "success") {
                    setCertificateInfo(response.data.result);
                    dispatch({
                        type: "CERT_PREVIEW",
                        previewState: true
                    });
            }else{
                Swal.fire({
                    icon: "info",
                    text: response.data.message,
                })
            }
        }).catch(err => {
            setLoading(false);
            Swal.fire({
                icon: "error",
                text: err,
            })
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
        instance({
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            data: { authid: authid, reviewEngineerId: handleApprovalEngineerChange, collectionId: collectionId },
            url: "/certificate/approval"
        }).then(response => {
            if (response.data.status === "success") {
                setProcessing(false);
                    Swal.fire({
                        icon: "success",
                        text: "successfully sent certificate(s) for review"
                    }).then(() => {
                        window.location.reload();
                        navigate("/draft");
                    })
                }
        }).catch(err => {
            Swal.fire({
                icon: "error",
                text: err
            })
            setProcessing(false);
        });
    }

    return <div className='draftList'>
    <Header> {
            loading ? <Preloader /> :
            <>
            {
                list?.length === 0  ? "nothing to display" :
                previewState
                ?
                <Preview type="draft" certificate={certificateInfo} /> :  <div className='container' >
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ textTransform: "capitalize" }}>Drafts</h3>
                    </div>
                </div>
                <div className='row'>
                <TableComp title="Certificate Drafts" data={list.map((item, index) => {return {"S/N": index + 1, "Company Name": item.company_name, "Action": <>
                    <Link to={`/draft/${item.collectionid}`}
                style={{textTransform: "capitalize", textDecoration: "none", color: "#000", fontWeight: 500, fontSize: "12px"}}
              > <FontAwesomeIcon className={'icon-edit'} icon="fa-solid fa-pencil" /></Link> 
              <Tooltip anchorSelect={'.icon-edit'} place="left">
              edit
          </Tooltip>
              {"  "}&nbsp;&nbsp;
              <FontAwesomeIcon className={'icon-trash'} icon="fa-solid fa-trash" disabled={processing} onClick={(e) => handleDraftDelete(e, item.collectionid)} />
              <Tooltip anchorSelect={'.icon-trash'} place="left">
              delete
          </Tooltip>
              {"  "}
              &nbsp;&nbsp;
              <FontAwesomeIcon className={'icon-preview'} icon="fa-solid fa-eye" disabled={processing} onClick={() => handleCertificateRequest(item.collectionid)} />
              <Tooltip anchorSelect={'.icon-preview'} place="right">
              preview
          </Tooltip>
              &nbsp;&nbsp;
              <FontAwesomeIcon className={'icon-share'} icon="fa-solid fa-share-from-square" disabled={processing} onClick={() => {
                handleShow();
                setCollectionId(item.collectionid)
            }} />
            <Tooltip anchorSelect={'.icon-share'} place="right">
            send for review
        </Tooltip>
              </>
            }})}
              />
                </div>
                  <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Select Engineer to Review Certificate</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Group controlId="formRole" className="mt-3">
                                                        <Form.Label>Engineer Name</Form.Label>
                                                        <Form.Select
                                                            name="role"
                                                            onChange={e => setHandleApprovalEngineerChange(e.target.value)}
                                                        >
                                                            <option value="">Select an engineer</option>
                                                            {
                                                                engineerList.map((list, index) => {
                                                                    return <option key={index} value={list.user_id}>{list.first_name + " " + list.last_name}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Button variant="primary" type="submit" className="mt-3" disabled={processing}>
                                                        {processing ? "sending certificate" : "submit"}
                                                    </Button>
                                                </Form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
            </div>
            }
                </>
        }
        </Header>
    </div>
}

export default DraftList;