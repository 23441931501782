import {  useEffect } from "react";
import { useStateValue } from "../../state/stateprovider";
import Input from "../../component/token/tokenComp";
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import instance from "../../axios";


const Token = () => {
  const [{ inputValues, focusedIndex, status, resetEmail, authid }, dispatch] = useStateValue();
  const navigate = useNavigate();
  const Swal = swal;

  useEffect(() => {
    if(resetEmail !== "" && authid === null){
      return;
    }
    navigate("/", {replace: true});
  }, []);

  useEffect(() => {
    if(handleArrayContent(inputValues)){
      if(inputValues?.length === 6){
        dispatch({ type: "VERIFY" });
        instance({
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: { token: inputValues.join(""), email: resetEmail},
          url: `/reset/checktoken`
        }).then((response) => {
            dispatch({ type: "VERIFY_SUCCESS" })
        
          switch (response.data.status) {
            case "success":
              navigate("/reset-password/password");
              break;
            case "invalid" || "not_found":
              Swal.fire({
                title: response.data.message,
                button: "Ok"
              });
              navigate("/reset-password/email");
              break;
            case "error":
              Swal.fire({
                icon: "error",
                title: "An error has occured. Try later",
                button: "Ok"
              });
              break;
            default:
              Swal.fire({
                icon: "info",
                title: response.data.message,
                button: "Ok"
              });
              break;
          }
        }).catch(err => {
            dispatch({ type: "VERIFY_SUCCESS" })
          
            if(err?.response?.data?.status){
              Swal.fire({
                icon: "error",
                title: err?.response?.data?.message,
                button: "Ok"
              });
            }else{
              Swal.fire({
                icon: "error",
                title: "An error has occured. Try later",
                button: "Ok"
              });
            }
        })
      }
    }
  }, [inputValues])

  function handleInput(index, value) {
     let processedValue = parseInt(value);
    if(Number.isInteger(processedValue) || value === ""){
      dispatch({ type: "INPUT", payload: { index, value } });
    }
  }

  function handleArrayContent(arr) {
    return arr.every(item => Number.isInteger(parseInt(item)))
  }
  function handleBack() {
    dispatch({ type: "BACK" });
  }

  function handlePaste(pastedValue) {
    if(handleArrayContent(pastedValue)){
      dispatch({ type: "PASTE", payload: { pastedValue } });
      if (pastedValue.length === 6) {
        dispatch({ type: "VERIFY" });
        dispatch({ type: "VERIFY_SUCCESS" });
      }
    }
  }

  function handleFocus(focusedIndex) {
    dispatch({ type: "FOCUS", payload: { focusedIndex } });
  }


  return (
    <div className="container" style={{height: 100 + "vh"}}>
      <div className="row"  style={{ width: 100 + "%", display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%" }}>
        <div className="col-lg-12"  style={{ width: 100 + "%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h5 className="mb-5">Enter Token</h5>
          <form className="mb-2" style={{width: 80  + "%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div className="inputs">
              {inputValues.map((value, index) => (
                <Input
                  key={index}
                  index={index}
                  value={value}
                  onChange={handleInput}
                  onBackspace={handleBack}
                  onPaste={handlePaste}
                  isFocused={index === focusedIndex}
                  onFocus={handleFocus}
                  isDisabled={status === "pending"}
                />
              ))}
            </div>
          </form>
          <p>
            A message with a verification code has been sent to {resetEmail}.
            Enter the code to continue.
          </p>
          <span>Didn’t get a verification code?</span>

          <p>{new Date().getFullYear()} Applied Analytical Systems.</p>

        </div>
      </div>
      <div className="col-lg-12">
      </div>
    </div>
  );
};

export default Token;