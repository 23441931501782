import React, { useEffect, useState } from 'react';
import Header from '../component/header';
import { Table } from 'react-bootstrap';
import instance from "../axios"
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Button, Modal } from 'react-bootstrap';
import Preloader from "../component/Preloader";
import { useStateValue } from '../state/stateprovider';

const StaffList = () => {
    const [{level, authid}] = useStateValue();
    const Swal = swal;
    // const authid = window.localStorage.getItem("authid");
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    // const level = window.localStorage.getItem("level");
    const [processing, setProcessing] = useState(false);
    const [show, setShow] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [userId, setUserId] = useState(null);
    const [update, setUpdate] = useState(null);
    const [modalType, setModalType] = useState(null);
    /**Modal State */
    const handleClose = () => setShow(false);

    const handleShow = (type, userid) => {
        setModalType(type);
        setUserId(userid);
        setShow(true);
    }


    useEffect(() => {
        if(level === 'admin' || level === 'moderator'){
            instance({
                method: "GET",
                headers: { "Content-Type": "application/json" },
                url: `/stafflist?authid=${authid}`
            }).then(response => {
                setLoading(false);
                if (response.data.status === "invalid") {
                    window.localStorage.clear();
                    navigate("/", {replace: true});
                } else if (response.data.status === "success") {
                    setList(response.data.result);
                }else{
                    Swal.fire({
                        icon: "info",
                        text: response.data.message
                    }).then(() => {
                        navigate("/dashboard");
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: "error",
                    text: err
                }).then(() => {
                    navigate("/dashboard", {replace: true});
                })
            });
    
            // Fetch roles
            instance({
                method: "GET",
                headers: { "Content-Type": "application/json" },
                url: `/staff/roles?authid=${authid}`
            }).then(response => {
                if (response.data.status === "success") {
                    setRoleList(response.data.result);
                } else if (response.data.status === "invalid") {
                    window.localStorage.clear();
                    navigate("/", {replace: true});
                }
            }).catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "An error occurred"
                });
            })
           
          }else{
            navigate("/dashboard", {replace: true});
          }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
        instance({
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            data: {authid: authid, type: modalType, userid: userId, update: update},
            url: `/staff/manage`
        }).then(response => {
            setProcessing(false);
            if (response.data.status === "success") {
                Swal.fire({
                    icon: "success",
                    text: "staff update was successful"
                }).then(() => {
                    window.location.reload();
                })
            }else if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/", {replace: true});
            }else if(response.data.status === "no_permission"){
                Swal.fire({
                    icon: "warning",
                    text: "You don't have permission to perform this function"
                }).then(() => {
                    navigate("/dashboard", {replace: true});
                })
            }
        }).catch(err => {
            Swal.fire({
                icon: "error",
                title: "An error occurred"
            });
        });
    }
    return <div className='draftList'>
        <Header> {
            loading ? <Preloader /> :
                <>
                    {
                        list?.length === 0 ? "nothing to display" :
                            <div className='container' >
                                <div className="row">
                                    <div className="col-lg-10 col-sm-12">
                                        <h3 style={{ textTransform: "capitalize" }}>Staff List</h3>
                                    </div>
                                    <div className="col-lg-2 col-sm-6">
                                    <Button variant="primary" className="w-100" onClick={() => navigate("/staff/create")}>
                                    create staff
                                </Button>
                                </div>
                                </div>
                                <div className='row'>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>
                                                    Name
                                                </th>
                                                <th>Email</th>
                                                <th>Code</th>
                                                <th>Role</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                list.map((items, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>
                                                            {items.first_name + " " + items.last_name}
                                                        </td>
                                                        <td>
                                                            {items.email}
                                                        </td>
                                                        <td>
                                                            {items.staffcode}
                                                        </td>
                                                        <td>
                                                            {items.rolename} {level === "admin" && items.rolename !== "admin"  ? <FontAwesomeIcon icon="fa-solid fa-pencil" onClick={() => handleShow("role", items.user_id)} /> : level === "moderator" && items.rolename !== "admin" ? <FontAwesomeIcon icon="fa-solid fa-pencil" onClick={() => handleShow("role", items.user_id)} />  : null}
                                                        </td>
                                                        <td>
                                                            {items.statusname} {level === "admin" && items.rolename !== "admin"  ? <FontAwesomeIcon icon="fa-solid fa-pencil" onClick={() => handleShow("status", items.user_id)} /> : level === "moderator" && items.rolename !== "admin" ? <FontAwesomeIcon icon="fa-solid fa-pencil" onClick={() => handleShow("status", items.user_id)} />   :  null}
                                                        </td>
                                                       
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                   <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Update Staff {modalType}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form onSubmit={handleSubmit}>
                                                <Form.Group controlId="formRole" className="mt-3">
                                                    {
                                                        modalType === "status" ?
                                                            <Form.Select
                                                                name="status"
                                                                onChange={e => setUpdate(e.target.value)}
                                                            >
                                                                <option value="">--update staff status--</option>
                                                                <option value="activate">Activate</option>
                                                                <option value="deactivate">Deactivate</option>
                                                            </Form.Select> :
                                                            <Form.Select
                                                                name="role"
                                                                onChange={e => setUpdate(e.target.value)}
                                                            >
                                                                {
                                                                    roleList.map((list, index) => {
                                                                        return <>
                                                                        <option>--update staff role --</option>
                                                                        <option key={index} value={list.id}>{list.rolename}</option></>
                                                                    })
                                                                }
                                                            </Form.Select>
                                                    }

                                                </Form.Group>
                                                <Button variant="primary" type="submit" className="mt-3" disabled={processing || update === null}>
                                                    {processing ? "updating" : "update"}
                                                </Button>
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                    }
                </>
        }
        </Header>
    </div>
}

export default StaffList;