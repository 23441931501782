import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import instance from "../../axios"
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import CreateFunc from "../../component/createFunc";
import {useStateValue} from "../../state/stateprovider";

const Draft = () => {
    const [{authid}] = useStateValue();
    const Swal = swal;
    const { collectionid } = useParams();
  // const authid = window.localStorage.getItem("authid");
    const navigate = useNavigate();
    const [draftCertificate, setDraftCertificate] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        instance({
            method: "GET",
            headers: { "Content-Type": "application/json" },
            url: `/draft/${collectionid}?authid=${authid}`
        }).then(response => {
            setLoading(false);
            if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/", {replace: true});
            } else if (response.data.status === "success") {
                setDraftCertificate(response.data.result);
            }else{
                Swal.fire({
                    icon: "info",
                    text: response.data.message
                })
            }
        }).catch(err => {
            setLoading(false);
            Swal.fire({
                icon: "error",
                text: err
            })
        });
    }, [])
    return ( <CreateFunc type="draft" data={draftCertificate} />  
        )
    
}
export default Draft;