import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import { Preview } from "../../component/preview";
import Header from '../../component/header';
import { Form, Dropdown, Button, Modal } from 'react-bootstrap';
import instance from "../../axios";
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useStateValue } from "../../state/stateprovider";



const CSVUploader = () => {
  const [{ previewState, authid }, dispatch] = useStateValue();
  const Swal = swal;
  const inputFile = useRef(null);
  // const authid = window.localStorage.getItem("authid");
  const [errors, setErrors] = useState([]);
  const [reviewedData, setReviewedData] = useState([]);
  const [address, setAddress] = useState("");
  const [customerLocation, setCustomerLocation] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [country, setCountry] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [customerShow, setCustomerShow] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [engineerList, setEngineerList] = useState([]);
  const [engineerFirstName, setEngineerFirstName] = useState("");
  const [engineerLastName, setEngineerLastName] = useState("");
  const [engineerId, setEngineerId] = useState("");
  const [certData, setCertData] = useState([])
  const navigate = useNavigate();


  /**Customer Modal State */
  const handleCustomerModalClose = () => setCustomerShow(false);
  const handleCustomerModalShow = () => setCustomerShow(true);

  useEffect(() => {
    instance({
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url: `/engineerlist?authid=${authid}`
    }).then(response => {
      if (response.data.status === "invalid") {
        window.localStorage.clear();
        navigate("/");
      } else {
        setEngineerList(response.data.result)
      }
    }).catch(err => {
      Swal.fire({
        icon: "error",
        text: err
      })
    });
  }, []);

  useEffect(() => {
    instance({
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url: `/company?authid=${authid}&companyname=${customerName}`
    }).then(response => {
      if (response.data.status === "success") {
        setCustomerList(response.data.result);
      } else if (response.data.status === "invalid") {
        window.localStorage.clear();
        navigate("/");
      }
    }).catch(err => {
      Swal.fire({
        icon: "error",
        text: err
      })
    })
  }, [filteredOptions]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    const filteredOptions = customerList.filter(option =>
      option.company_name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
    setShowDropdown(true);
  };
  const handleSelectOption = (key, text, address, state) => {
    setInputValue(text);
    setCustomerName(key);
    setAddress(address);
    setCustomerLocation(state);
    setFilteredOptions([]);
    setShowDropdown(false);
  };

  const handleNewCustomer = (event) => {
    event.preventDefault();
    setProcessing(true);
    instance({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { authid: authid, customerName: customerName, address: address, state: customerLocation, country: country, customerCode: customerCode },
      url: '/customer/new'
    }).then(response => {
      setProcessing(false);
      if (response.data.status === "success") {
        Swal.fire({
          icon: "success",
          text: 'created a new customer'
        }).then(() => {
          setCustomerName("");
          setCustomerLocation("");
          setAddress("");
          setCustomerCode("");
          setCountry("");
        })
      } else if (response.data.status === "duplicate") {
        Swal.fire({
          icon: "duplicate",
          text: 'customer already exists'
        }).then(() => {
          setCustomerName("");
          setCustomerLocation("");
          setAddress("");
          setCustomerCode("");
          setCountry("");
        })
      }
    }).catch(err => {
      Swal.fire({
        icon: "error",
        text: err.response?.data?.message || "an error has occured"
      })
      setProcessing(false);
      setCustomerName("");
      setCustomerLocation("");
      setAddress("");
      setCustomerCode("");
      setCountry("");
    });

  }

  const handleEngineerList = (input) => {
    const filteredList = engineerList.find(option => { return parseInt(option.user_id) === parseInt(input) });
    if (filteredList) {
      setEngineerId(filteredList.user_id);
      setEngineerFirstName(filteredList.first_name)
      setEngineerLastName(filteredList.last_name);
    }

  }
 

  // Define a schema for validation
  const validationSchema = yup.object().shape({
    instrument_id: yup.object({
      instrumentmodel: yup.string().required('Instrument model is required'),
      instrumentserial: yup.string().required('Instrument serial is required'),
      instrumentdescription: yup.string().required('Instrument description is required')
    }),
    standard: yup.array().required('Standard is required').min(1, "there must be at least one standard"),
    calibration_date: yup.date().required('Calibration date is required'),
    calibration_due_date: yup.date().required('Calibration due date is required').test('is-after', 'calibration due date must be after calibration date', function (value) {
      const { calibration_date } = this.parent;
      return calibration_date && value && value > calibration_date;
    }),
    conditions: yup.object().required('Conditions are required'),
    calibration_data: yup.object({
      rows: yup.array().required('Calibration data rows are required').min(1, "there must be at least one row in the calibration data table"),
      headers: yup.array().required('Calibration data headers are required').min(1, "there must be at least one header in the calibration data table"),
      rowHeaders: yup.array(),
      numColumns: yup.number().required('Number of columns in calibration data is required').min(1, "there must be at least one header in the calibration data table")
    }),
    staff_comment: yup.string().required('Staff comment is required')
  });

  function processParsedDataArray(dataArray) {
    return dataArray.map((data) => processParsedData(data));
  }

  function processParsedData(data) {
    const processedData = {};

    for (const [key, value] of Object.entries(data)) {
      const lowerKey = key.toLowerCase();

      if (lowerKey.startsWith("standard")) {
        const standardKey = "standard";
        if (!processedData[standardKey]) {
          processedData[standardKey] = [];
        }
        const tempStandard = [];
        tempStandard.push(parseStandard(value));
        const newTempStandard = tempStandard.filter(obj => !(obj && Object.keys(obj).length === 0 && obj.constructor === Object));
        processedData[standardKey].push(...newTempStandard);
      } else if (lowerKey.startsWith("row") && lowerKey !== "rowheaders") {
        const rowKey = "rows";
        if (!processedData[rowKey]) {
          processedData[rowKey] = [];
        }

        const tempRow = [];
        tempRow.push(value.split(","));
        let newTempRow = tempRow.filter((obj, index) => (obj[index] !== ""));
        let newProcessedTempRow = newTempRow.filter((obj) => (obj.length > 0));
        processedData[rowKey].push(...newProcessedTempRow);
      } else if (lowerKey === "conditions") {
        processedData.conditions = parseConditions(value);
      } else if (lowerKey === "headers") {
        const keyHeader = "headers";
        const headers = value.split(",");
        if (!processedData[keyHeader]) {
          processedData[keyHeader] = [];
        }

        if (headers[0] !== "") {
          processedData[keyHeader].push(...headers);
        }

      } else if (lowerKey === "rowheaders") {
        const keyRowHeader = "rowheaders";
        const rowHeaders = value.split(",");
        if (!processedData[keyRowHeader]) {
          processedData[keyRowHeader] = [];
        }
        if (rowHeaders[0] !== "") {
          processedData[keyRowHeader].push(...rowHeaders);
        }
      } else {
        processedData[lowerKey] = value;
      }
    }
    return processedData;
  }

  function parseStandard(standardString) {
    const standardParts = standardString.split(",");
    const standardObject = {};
    for (let i = 0; i < standardParts.length; i += 2) {
      const key = standardParts[i]?.trim();
      const val = standardParts[i + 1]?.trim();
      if (key !== "") {
        standardObject[key] = val;
      }
    }
    return standardObject;
  }

  function parseConditions(conditionsString) {
    const conditionsParts = conditionsString.split(",");
    const conditionsObject = {};
    for (let i = 0; i < conditionsParts.length; i += 2) {
      const key = conditionsParts[i]?.trim();
      const val = conditionsParts[i + 1]?.trim();
      if (key !== "") {
        conditionsObject[key] = val;
      }
    }
    return conditionsObject;
  }

  // Function to convert stringified arrays or objects to proper format
  const convertToProperFormat = (data) => {
    return data.map(item => {
      Object.keys(item).forEach(key => {
        if (typeof item[key] === "string") {
          try {
            const parsedValue = JSON.parse(item[key]);
            // If parsing succeeds and the result is an array or object, replace the original string
            if (Array.isArray(parsedValue)) {
              item[key] = parsedValue;
            } else if (typeof parsedValue === 'object' && parsedValue !== null) {
              item[key] = parsedValue;
            }
          } catch (e) {
            // If JSON.parse fails, the value remains a string
          }
        }
      });
      return item;
    });
  };
  function transformData(data) {
    return data.map(item => {
      return {
        instrument_id: {
          instrumentmodel: item.model,
          instrumentserial: item.serial,
          instrumentdescription: item.description
        },
        standard: item.standard,
        calibration_date: formatDate(item["calibration date"]).split("T")[0],
        calibration_due_date: formatDate(item["calibration due date"]).split("T")[0],
        conditions: item.conditions,
        calibration_data: {
          rows: item["rows"],
          headers: item["headers"],
          rowHeaders: item["rowheaders"],
          numColumns: item["headers"]?.length || 0
        },
        staff_comment: item["staff comment"],
        "engineerid": engineerId,
        "company_name": inputValue,
        "address": address,
        "state": customerLocation,
        "country": country,
        "first_name": engineerFirstName,
        "last_name": engineerLastName,
      };
    });
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  // Function to validate the structure of the parsed JSON
  // const validateJSON = (data) => {
  //   const errors = [];

  //   const validateObject = (obj, structure, path = '') => {
  //     for (const key in structure) {
  //       const currentPath = path ? `${path}.${key}` : key;

  //       if (typeof structure[key] === 'object' && !Array.isArray(structure[key])) {
  //         if (typeof obj[key] !== 'object') {
  //           errors.push(`Expected an object at ${currentPath}, but found ${obj[key]}`);
  //         } else {
  //           validateObject(obj[key], structure[key], currentPath);
  //         }
  //       } else if (Array.isArray(structure[key])) {
  //         if (!Array.isArray(obj[key])) {
  //           errors.push(`Expected an array at ${currentPath}, but found ${typeof obj[key]}`);
  //         }
  //       }
  //       //  else if (typeof obj[key] !== structure[key]) {
  //       //   errors.push(`Expected ${structure[key]} at ${currentPath}, but found ${typeof obj[key]}`);
  //       // }
  //     }
  //   };

  //   data.forEach((item, index) => validateObject(item, expectedStructure, `row ${index + 1}`));

  //   return errors;
  // };

  // Asynchronous validation function for each record
  const validateRecord = async (item, index) => {

    try {
      // Validate the record against the schema
      await validationSchema.validate(item, { abortEarly: false });
    } catch (err) {
      // Catch validation errors and format them
      return err.inner.map(error => `Certificate ${index + 1}: ${error.message}`);
    }
  };


  // Function to validate all records in parallel
  const validateJSON = async (data) => {
    const validationPromises = data.map((item, index) => validateRecord(item, index));

    // Run all validations in parallel
    const validationResults = await Promise.all(validationPromises);

    // Flatten the array and filter out null (valid) results
    const errors = validationResults.flat().filter(error => error !== undefined);
    return errors;
  };

  // Handle file upload and parsing
  const handleFileUpload = (event) => {
    const file = event;
    if (file?.type === "text/csv") {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (result) => {
          if (result.errors.length) {
            setErrors(result.errors.map(error => `Parsing error: ${error.message}`));
          } else {
            const preProcessed = processParsedDataArray(result?.data);
            const transformDataResult = transformData(preProcessed);
            const formattedData = convertToProperFormat(transformDataResult);
            const validationErrors = await validateJSON(formattedData);
            if (validationErrors.length > 0) {
              setErrors(validationErrors);
            } else {
              setReviewedData(formattedData);
            }
          }
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "Expected a CSV file"
      })
      inputFile.current.value = "";
    }
  };

  return (
  <>
    { previewState  ? <Preview type="bulk" certificate={certData} /> 
        :
     <Header>
      <div className='container'>
        <div className='row'>
          <div className="col-lg-12 col-md-12">
            {errors.length > 0 && (
              <div>

                <h4>Errors:</h4>
                <ul>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className='col-lg-12 col-md-12'>
            <div className='card' style={{ border: 0 }}>
              <div className="card-body">
                <div className="card-top">
                  <h4 className="card-title">Create Bulk Certificate</h4>
                  <button className="btn btn-primary" style={{ textTransform: "capitalize", backgroundColor: "#0a1f6a", borderColor: "#0a1f6a" }} onClick={handleCustomerModalShow}>add customer </button>
                </div>
                <form className='loginForm'  style={{ height: 'fit-content' }}>
                  <div className="mb-3">
                    <label htmlFor="customername" className="form-label">Customer Name</label>
                    <Form.Group controlId="formTypeahead">
                      <Form.Control
                        type="text"
                        placeholder="Name of Customer"
                        value={inputValue}
                        onChange={handleInputChange}
                        onFocus={() => setShowDropdown(true)}
                        required
                      />
                      {showDropdown && filteredOptions.length > 0 && (
                        <Dropdown className="mt-1" show={showDropdown}>
                          <Dropdown.Menu>
                            {filteredOptions.map((option, index) => (
                              <Dropdown.Item
                                key={index}
                                onClick={() => handleSelectOption(option.company_id, option.company_name, option.address, option.state)}
                              >
                                {option.company_name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </Form.Group>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="customeraddress" className="form-label">Enter Address</label>
                    <input type="text" className="form-control" id="customeraddress"
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="state" className="form-label">Enter State</label>
                    <input type="text" className="form-control" id="customerstate"
                      value={customerLocation}
                      onChange={e => setCustomerLocation(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Group controlId={`input_label`}>
                      <Form.Label>Engineer Name</Form.Label>
                      <Form.Select
                        name="engineer"
                        onChange={e => handleEngineerList(e.target.value)}
                        required
                      >
                        <option>--select Engineer Name--</option>
                        {engineerList.map((list, index) => (
                          <option
                            key={index}
                            value={list.user_id}
                          >
                            {list.first_name + " " + list.last_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="mb-3">
                    <input type="file" accept=".csv"  ref={inputFile}  onChange={e => handleFileUpload(e.target.files[0])} required />
                  </div>
                  {
                    (errors?.length === 0  && reviewedData?.length > 0) && <div className="mb-3">
                      <button className="btn btn-secondary" onClick={() => {
                        setCertData([{authid: authid, certificateData: reviewedData, customername: customerName, address: address, location: customerLocation, status: "new"}])
                        dispatch({
                          type: "CERT_PREVIEW",
                          previewState: true
                        });
                      }}>Preview</button>
                    </div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={customerShow} onHide={handleCustomerModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleNewCustomer}>
            <div className="mb-3">
              <label htmlFor="customername" className="form-label">Customer Name</label>
              <Form.Group controlId="formTypeahead">
                <Form.Control
                  type="text"
                  placeholder="Name of Customer"
                  value={customerName}
                  onChange={e => setCustomerName(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="mb-3">
              <label htmlFor="customercode" className="form-label">Enter Customer Code</label>
              <input type="text" className="form-control" id="customercode"
                value={customerCode}
                onChange={e => setCustomerCode(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="customeraddress" className="form-label">Enter Address</label>
              <input type="text" className="form-control" id="customeraddress"
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="state" className="form-label">Enter State</label>
              <input type="text" className="form-control" id="customerstate"
                value={customerLocation}
                onChange={e => setCustomerLocation(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="country" className="form-label">Enter Country</label>
              <input type="text" className="form-control" id="customercountry"
                value={country}
                onChange={e => setCountry(e.target.value)}
              />
            </div>
            <Button variant="primary" type="submit" className="mt-3" disabled={processing}>
              {processing ? "creating customer" : "submit"}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCustomerModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </Header>
  }
  </>
  );
};

export default CSVUploader;
