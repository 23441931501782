import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Header from './header';
import { Table, Form, Dropdown, Button, Modal } from 'react-bootstrap';
import instance from "../axios"
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { Preview } from './preview.js';
import "../asset/css/style.css";
import { useStateValue } from "../state/stateprovider.js";
import Preloader from './Preloader';


const CreateFunc = ({ type, data }) => {
    const [{ previewState, authid }, dispatch] = useStateValue();

    const Swal = swal;
    const { collectionid } = useParams();
    const [numofinstrument, setNumofinstrument] = useState(0);
    const [formData, setFormData] = useState(Array.from({ length: numofinstrument }, () => ({})));
    const [tables, setTables] = useState([]);
    const [standards, setStandards] = useState(Array.from({ length: numofinstrument }, () => ([])));
    const [testCondition, settestCondition] = useState(Array.from({ length: numofinstrument }, () => ([])));
    const navigate = useNavigate();
    const [customerName, setCustomerName] = useState("");
    const [customerList, setCustomerList] = useState([]);
    const [address, setAddress] = useState("");
    const [customerLocation, setCustomerLocation] = useState("");
    const [customerCode, setCustomerCode] = useState("");
    const [country, setCountry] = useState("");
    const [instrumentDetailContainer, setInstrumentDetailContainer] = useState(Array.from({ length: numofinstrument }, () => ({})));
    const [engineerList, setEngineerList] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [blockDisplay, setBlockDisplay] = useState(0);
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [show, setShow] = useState(false);
    const [customerShow, setCustomerShow] = useState(false);
    const [deleteProcessing, setDeleteProcessing] = useState(false);
    const [handleApprovalEngineerChange, setHandleApprovalEngineerChange] = useState(null);
    const [includeHorizontalHeaders, setIncludeHorizontalHeaders] = useState([[false, 0]]);
    const leastInstrumentNum = 1;
    const highestInstrumentNum = 50

    /**Modal State */
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /**Customer Modal State */
    const handleCustomerModalClose = () => setCustomerShow(false);
    const handleCustomerModalShow = () => setCustomerShow(true);

    useEffect(() => {
        if (data?.length > 50) {
            Swal.fire({
                icon: "info",
                text: "Please use the bulk upload option for more than 50 certificates"
            }).then(() => {
                navigate("/bulk-certifiate");
            })
        }else if(type !== "create"){
            setFormData(type === "create" ? Array.from({ length: numofinstrument }, () => ({})) : data);
            setStandards(type === "create" ? Array.from({ length: numofinstrument }, () => ([]))
                : Array.from({ length: data?.length }, (_, index) => data[index]?.standard === null ? Array.from({ length: numofinstrument }, () => ([])) : (data[index]?.standard?.map((standardObj) => Object.entries(standardObj)?.map(([label, value]) => ({ label, value }))))));
    
            settestCondition(type === "create" ? Array.from({ length: numofinstrument }, () => ([])) : Array.from({ length: data?.length }, (_, index) => data[index]?.conditions === null ? Array.from({ length: numofinstrument }, () => ([])) : (Object.entries(data[index]?.conditions)?.map(([label, value]) => ({ label, value })))
            ));
    
            setInstrumentDetailContainer(type === "create" ? Array.from({ length: numofinstrument }, (_, index) => ({}))  : Array.from({ length: data?.length }, (_, index) => data[index]?.instrument_id === null ? Array.from({ length:  data?.length }, (_, index) => ({})) : data[index]?.instrument_id )
            );
            setTables(
                data.map((item, index) => 
                    item?.calibration_data
                        ? {
                            numColumns: item.calibration_data.numColumns,
                            headers: [...item.calibration_data.headers],
                            rows: [...item.calibration_data.rows],
                            rowHeaders: item.calibration_data.rowHeaders ? [...item.calibration_data.rowHeaders] : [],
                        }
                        : {
                            // Placeholder table for instruments without calibration data
                            numColumns: 2,
                            headers: Array.from({ length: 2 }, (_, i) => ''),
                            rows: [Array.from({ length: 2 }, () => '')],
                            rowHeaders: [],
                        }
                ).concat(
                    // Ensure tables for any additional instruments up to `numofinstrument`
                    Array.from({ length: Math.max(0, numofinstrument - data.length) }, () => ({
                        numColumns: 2,
                        headers: Array.from({ length: 2 }, (_, i) => `Header ${i + 1}`),
                        rows: [Array.from({ length: 2 }, () => '')],
                        rowHeaders: [],
                    }))
                )
            );
            
            // setTables(
            //     type === "create"
            //         ? Array.from({ length: numofinstrument }, () => ({
            //             numColumns: 2,
            //             headers: Array.from({ length: 2 }, (_, index) => `Header ${index + 1}`),
            //             rows: [Array.from({ length: 2 }, () => '')],
            //             rowHeaders: [],
            //         }))
            //         :  data.some(item => item?.calibration_data === null)
            //             ? [{
            //                 numColumns: 2,
            //                 headers: Array.from({ length: 2 }, (_, index) => `Header ${index + 1}`),
            //                 rows: [Array.from({ length: 2 }, () => '')],
            //                 rowHeaders: [],
            //             }]
            //             : data.map(item => ({
            //                 numColumns: item.calibration_data.numColumns,
            //                 headers: Array.from({ length: item.calibration_data.numColumns }, (_, index) => item.calibration_data.headers[index]),
            //                 rows: [...item.calibration_data.rows],
            //                 rowHeaders: item.calibration_data.rowHeaders ? [...item.calibration_data.rowHeaders] : []
            //             }))
            // );
            setBlockDisplay((type === "create" || type === "modify") ? 0 : 1);
            setAddress(type === "create" ? "" :  data[0]?.address);
            setCustomerLocation(type === "create" ? "" :data[0]?.state);
            setCustomerName(type === "create" ? "" : data[0]?.company_id);
            setInputValue(type === "create" ? "" : data[0]?.company_name);
            setNumofinstrument((type === "create" || type === "modify") ? 0 : data?.length);
        }
    }, [type, data])

    useEffect(() => {
        instance({
            method: "GET",
            headers: { "Content-Type": "application/json" },
            url: `/company?authid=${authid}&companyname=${customerName}`
        }).then(response => {
            if (response.data.status === "success") {
                setCustomerList(response.data.result);
            } else if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/");
            }
        }).catch(err => {
            Swal.fire({
                icon: "error",
                text: err
            })
        });

        instance({
            method: "GET",
            headers: { "Content-Type": "application/json" },
            url: `/engineerlist?authid=${authid}&type=approval`
        }).then(response => {
            if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/");
            } else {
                setEngineerList(response.data.result)
            }
        }).catch(err => {
            Swal.fire({
                icon: "error",
                text: err
            })
        });


    }, []);

    useEffect(() => {
        tables.forEach((element, index) => {
            CertificateData(index, '', 'calibration_data', element);
        });
    }, [tables]);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setInputValue(inputValue);
        const filteredOptions = customerList.filter(option =>
            option.company_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredOptions(filteredOptions);
        setShowDropdown(true);
    };
    const handleEngineerList = (input, instrumentIndex) => {
        const filteredList = engineerList.find(option => { return parseInt(option.user_id) === parseInt(input) });
        if (filteredList) {
            CertificateData(instrumentIndex, '', 'engineerid', filteredList.user_id);
            CertificateData(instrumentIndex, '', 'first_name', filteredList.first_name);
            CertificateData(instrumentIndex, '', 'last_name', filteredList.last_name);
        }
    }

    const handleSelectOption = (key, text, address, state) => {
        setInputValue(text);
        setCustomerName(key);
        setAddress(address);
        setCustomerLocation(state);
        setFilteredOptions([]);
        setShowDropdown(false);

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setProcessing(true);
        instance({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: { authid: authid, certificateData: formData, customername: customerName, address: address, location: customerLocation, status: type === "create" ? "new" : type === "modify" ? "new" : "draft", reviewEngineerId: handleApprovalEngineerChange },
            url: "/new-certificate"
        }).then(response => {
            if (response.data.status === "success") {
                setProcessing(false);
                if (type === "create" || type === "modify") {
                    Swal.fire({
                        icon: "success",
                        text: "successfully drafted " + formData?.length + " new certificate(s)"
                    }).then(() => {
                        navigate("/draft");
                    })
                } else if (type === "draft" && handleApprovalEngineerChange === null) {
                    Swal.fire({
                        icon: "success",
                        text: "successfully updated " + formData?.length + " certificate(s)"
                    }).then(() => {
                        navigate("/draft");
                    })
                } else if (type === "draft" && handleApprovalEngineerChange !== null) {
                    Swal.fire({
                        icon: "success",
                        text: "successfully sent " + formData?.length  + " certificate(s) to be reviewed"
                    }).then(() => {
                        navigate("/draft");
                    })
                }
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Couldn't  create certificate(s)"
                });
                setProcessing(false);
            }
        }).catch(err => {
            Swal.fire({
                icon: "error",
                text: err
            })
            setProcessing(false);
        });
    };

    const handleDraftDelete = async (e) => {
        e.preventDefault();
        setDeleteProcessing(true);
        try {
            const response = await instance.delete("/draft/trash", {
               data: { authid: authid,
                collectionId: formData[0].collectionid
                }
            });
            setDeleteProcessing(false);
            if (response.data.status === "success") {
                Swal.fire({
                    icon: "success",
                    text: "successfully deleted " + formData?.length + " certificate draft(s)"
                }).then(() => {
                    navigate("/draft");
                })
            } else if (response.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/", { replace: true });
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Couldn't  create delete draft(s)"
                });
            }
        } catch (err) {
            setDeleteProcessing(false);
            Swal.fire({
                icon: "error",
                text: "An error occurred"
            });
        }

    }

    const handleNewCustomer = (event) => {
        event.preventDefault();
        setProcessing(true);
        instance({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: { authid: authid, customerName: customerName, address: address, state: customerLocation, country: country, customerCode: customerCode },
            url: '/customer/new'
        }).then(response => {
            setProcessing(false);
            if (response.data.status === "success") {
                Swal.fire({
                    icon: "success",
                    text: 'created a new customer'
                }).then(() => {
                    setCustomerName("");
                    setCustomerLocation("");
                    setAddress("");
                    setCustomerCode("");
                    setCountry("");
                })
            } else if (response.data.status === "duplicate") {
                Swal.fire({
                    icon: "duplicate",
                    text: 'customer already exists'
                }).then(() => {
                    setCustomerName("");
                    setCustomerLocation("");
                    setAddress("");
                    setCustomerCode("");
                    setCountry("");
                })
            }
        }).catch(err => {
            Swal.fire({
                icon: "error",
                text: err.response?.data?.message || "an error has occured"
            })
            setProcessing(false);
            setCustomerName("");
            setCustomerLocation("");
            setAddress("");
            setCustomerCode("");
            setCountry("");
        });

    }

    const CertificateData = (index, instrumentNumber, key, value) => {
        setFormData(prevFormData => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index] = { ...updatedFormData[index], [key]: value };
            return updatedFormData;
        });
    };

    const previewCert = e => {
        e.preventDefault();
        dispatch({
            type: "CERT_PREVIEW",
            previewState: true
        });
    }

    const handleAddTable = () => {
        setTables((prevTables) => [
            ...prevTables,
            {
                numColumns: 2,
                headers: Array.from({ length: 2 }, (_, index) => ''),
                rows: [Array.from({ length: 2 }, () => '')],
                rowHeaders: [],
            },
        ]);
    };
    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(this, args), delay);
        };
    };
    const numberOfInstrument = debounce((event) => {
        // Calculate the number of instruments based on the sum of data length and event value
        const eventNum = parseInt(event.target.value) || 1;
        const dataNum = data?.length || 0;
        const num = (eventNum - 1) + dataNum;
        if (eventNum > 50 || num > 50) {
            Swal.fire({
                icon: "info",
                text: "Please use the bulk upload option for more than 50 certificates"
            }).then(() => {
                navigate("/bulk-certifiate");
            })
        } else {
            // Only load up to the current number of instruments
            const numToLoad = type === "modify" ? num : eventNum;
            setNumofinstrument(numToLoad);
            setFormData(type === "modify" ? data : Array.from({ length: numToLoad }, () => ({})));
            setStandards(type === "modify" ?
                Array.from({ length: numToLoad }, (_, index) =>
                  data[index]?.standard !== undefined ? data[index]?.standard.map((standardObj) =>
                        Object.entries(standardObj).map(([label, value]) => ({ label, value }))
                    ) : [[{ label: '', value: '' }]]
                ) :
                Array.from({ length: numToLoad }, () => ([[{ label: '', value: '' }]]))
            );

            settestCondition(type === "modify" ?
                Array.from({ length: numToLoad }, (_, index) =>
                    data[index]?.conditions !== undefined ? Object.entries(data[index].conditions).map(([label, value]) => ({ label, value }))
                        : [{ label: '', value: '' }]
                ) :
                Array.from({ length: numToLoad }, () => [{ label: '', value: '' }])
            );

            setInstrumentDetailContainer(type === "modify" ?
                Array.from({ length: numToLoad }, (_, index) => data[index]?.instrument_id || {}) :
                Array.from({ length: numToLoad }, () => ({}))
            );

            setTables(type === "modify" ?
                Array.from({ length: numToLoad }, (_, index) => data[index]?.calibration_data !== undefined ? {
                    numColumns: data[index]?.calibration_data?.numColumns,
                    headers: Array.from({ length: numToLoad }, (_, headerIndex) => [data[index]?.calibration_data?.headers[headerIndex]]),
                    rows: [...data[index]?.calibration_data?.rows],
                    rowHeaders: data[index]?.calibration_data?.rowHeaders ? [...data[index]?.calibration_data?.rowHeaders] : []
                } : {
                    numColumns: 2,
                    headers: Array.from({ length: 2 }, (_, index) => ''),
                    rows: [Array.from({ length: 2 }, () => '')],
                    rowHeaders: [],
                },) :
                []
            );
            if (type !== "modify") {
                for (let i = 0; i < numToLoad; i++) {
                    handleAddTable();
                }
            }
        }
    }, 300);

    const handleAddStandard = (instrumentIndex) => {
        const updatedStandards = [...standards];
        updatedStandards[instrumentIndex]?.push([{ label: '', value: '' }]);
        setStandards(updatedStandards);
    };

    const handleStandrdInput = (instrumentIndex, instrumentStandardIndex) => {
        const updatedStandards = [...standards];
        updatedStandards[instrumentIndex][instrumentStandardIndex].push({ label: '', value: '' });
        setStandards(updatedStandards);
    };
    const handleStanardInputChange = (instrumentIndex, standardIndex, field, value, inputIndex) => {
        const AllStandards = [...standards];
        AllStandards[instrumentIndex][standardIndex][inputIndex] = {
            ...AllStandards[instrumentIndex][standardIndex][inputIndex],
            [field]: value
        }
        setStandards(AllStandards);
        const processStandard = AllStandards;
        const updatedData = processStandard.map(instrument =>
            instrument.map(standard =>
                standard.reduce((acc, { label, value }) => {
                    acc[label] = value;
                    return acc;
                }, {})
            )
        );
        updatedData.forEach((element, index) => {
            CertificateData(index, '', 'standard', element);
        })
    };
    const handletestConditionInput = (index) => {
        const updatedTestCondition = [...testCondition];
        updatedTestCondition[index].push({ label: '', value: '' });
        settestCondition(updatedTestCondition);
    };
    const handleTestConditionInputChange = (instrumentIndex, certInfoIndex, field, value) => {
        const updatedTestCondition = [...testCondition];
        updatedTestCondition[instrumentIndex][certInfoIndex] = {
            ...updatedTestCondition[instrumentIndex][certInfoIndex],
            [field]: value
        }
        settestCondition(updatedTestCondition);
        const processTestCondition = updatedTestCondition
        const updatedData = processTestCondition.map(condition =>
            condition.reduce((acc, { label, value }) => {
                acc[label] = value;
                return acc;
            }, {})
        );
        updatedData.forEach((element, index) => {
            CertificateData(index, '', 'conditions', element);
        })
    };
    const handleInstrumentDetailInputChange = (instrumentIndex, infoIndex, field, value) => {
        setInstrumentDetailContainer(prevData => {
           const updateData = [...prevData];
           updateData[instrumentIndex] = { ...updateData[instrumentIndex], [field]: value };
           updateData.forEach((element, index) => {
                CertificateData(index, '', 'instrument_id', element);
            })
            return updateData;
        });
    }

    /**Calibration Data */
    const handleNumColumnsChange = (tableIndex, e) => {
        const columns = parseInt(e.target.value);
        setTables((prevTables) =>
            prevTables.map((table, index) => {
                if (index === tableIndex) {
                    return {
                        ...table,
                        numColumns: columns,
                        headers: Array.from({ length: columns }, (_, idx) => table.headers[idx] || ''),
                        rows: table.rows.map((row) => Array.from({ length: columns }, (_, idx) => row[idx] || '')),
                    };
                }
                return table;
            })
        );
        tables.forEach((element, index) => {
            CertificateData(index, '', 'calibration_data', element);
        })
    };

    const handleHeaderChange = (tableIndex, colIndex, value) => {
        setTables((prevTables) =>
            prevTables.map((table, index) => {
                if (index === tableIndex) {
                    const updatedHeaders = [...table.headers];
                    updatedHeaders[colIndex] = value;
                    return { ...table, headers: updatedHeaders };
                }
                return table;
            })
        );
        tables.forEach((element, index) => {
            CertificateData(index, '', 'calibration_data', element);
        })
    };

    const handleAddRow = (tableIndex) => {
        setTables((prevTables) =>
            prevTables.map((table, index) => {
                if (index === tableIndex) {
                    return { ...table, rows: [...table.rows, Array.from({ length: table.numColumns }, () => '')] };
                }
                return table;
            })
        );
    };

    const handleDeleteRow = (tableIndex, rowIndex) => {
        setTables((prevTables) =>
            prevTables.map((table, index) => {
                if (index === tableIndex) {
                    return { ...table, rows: table.rows.filter((_, i) => i !== rowIndex) };
                }
                    return table;
            })
        );
    };

    const handleRowChange = (tableIndex, rowIndex, colIndex, value) => {
        setTables((prevTables) =>
            prevTables.map((table, index) => {
                if (index === tableIndex) {
                    const updatedRows = [...table.rows];
                    updatedRows[rowIndex][colIndex] = value;
                    return { ...table, rows: updatedRows };
                }
                return table;
            })
        );
        tables.forEach((element, index) => {
            CertificateData(index, '', 'calibration_data', element);
        })
    };



const handleToggleHorizontalHeaders = (tableIndex) => {
    setIncludeHorizontalHeaders((prevState) => {
        const updateState = [...prevState];
        updateState[tableIndex] = [!updateState[tableIndex]?.[0], tableIndex];
        return updateState;

    });
};



    const handleRowHeaderChange = (tableIndex, rowIndex, value) => {
        setTables((prevTables) =>
            prevTables.map((table, index) => {
                if (index === tableIndex) {
                    const updatedRowHeaders = [...table.rowHeaders];
                    updatedRowHeaders[rowIndex] = value;
                    return { ...table, rowHeaders: updatedRowHeaders };
                }
                return table;
            })
        );
        tables.forEach((element, index) => {
            CertificateData(index, '', 'calibration_data', element);
        })
    };


    /*** Instrument Details Function ***/
    const instrumentDetails = useMemo(() => {
        return [...Array(numofinstrument)].map((_, index) => (
            <div key={index}>
                <h4 className="card-title">Instrument Identification #{index + 1}</h4>
                <form className='loginForm' style={{ height: 'fit-content' }}>
                    <div className="mb-3">
                        <label htmlFor="instrumentdescription" className="form-label">Description</label>
                        <input type="text" className="form-control" id="instrumentdescription" aria-describedby="instrumentdescription"
                            defaultValue={formData[index]?.instrument_id?.instrumentdescription || ''}
                            onChange={(e) => {
                                handleInstrumentDetailInputChange(index, 0, 'instrumentdescription', e.target.value)
                            }
                            }
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="instrumentmodel" className="form-label">Model No</label>
                        <input type="text" className="form-control" id="instrumentmodel"
                            defaultValue={formData[index]?.instrument_id?.instrumentmodel || ''}
                            onChange={(e) => {
                                handleInstrumentDetailInputChange(index, 1, 'instrumentmodel', e.target.value)
                            }
                            }
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="instrumentserial" className="form-label">Serial No</label>
                        <input type="text" className="form-control" id="instrumentserial"
                            defaultValue={formData[index]?.instrument_id?.instrumentserial || ''}
                            onChange={(e) => {
                                handleInstrumentDetailInputChange(index, 2, 'instrumentserial', e.target.value)
                            }}
                        />
                    </div>
                </form>
            </div>
        ));
    }, [numofinstrument, instrumentDetailContainer]);

    const instrumentStandards = useMemo(() => {
        return [...Array(numofinstrument)].map((_, instrumentIndex) => (
            <div key={instrumentIndex}>
                <h4 className="card-title">Instrument #{instrumentIndex + 1} Standards</h4>
                <form className='loginForm' style={{ height: 'fit-content' }}>
                    <div className="mb-3">
                        {standards?.map((standard, standardIndex) => (
                            <div key={standardIndex}>
                                {
                                    standardIndex === instrumentIndex ?
                                        <>
                                            {standard?.map((input, sIndex) => (
                                                <div key={sIndex}>
                                                    <h6>Standard {sIndex + 1}</h6>
                                                    {
                                                        input?.map((i, inputIndex) => {
                                                            return <div key={inputIndex}>
                                                                <Form.Group controlId={`input_label_${standardIndex}_${sIndex}_${inputIndex}`}>
                                                                    <Form.Label>Label</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        defaultValue={i?.label}
                                                                        onChange={(e) => handleStanardInputChange(instrumentIndex, sIndex, 'label', e.target.value, inputIndex)}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group controlId={`input_value_${standardIndex}_${sIndex}_${inputIndex}`}>
                                                                    <Form.Label>Value</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        defaultValue={i?.value}
                                                                        onChange={(e) => handleStanardInputChange(instrumentIndex, sIndex, 'value', e.target.value, inputIndex)}
                                                                    />
                                                                </Form.Group>
                                                            </div>
                                                        })
                                                    }
                                                    <Button variant="primary" onClick={() => handleStandrdInput(instrumentIndex, sIndex)}>
                                                        Add Input
                                                    </Button>
                                                </div>
                                            ))}
                                            <hr />
                                            <Button variant="primary" onClick={() => handleAddStandard(instrumentIndex)}>
                                                Add Standard
                                            </Button>
                                        </> : <></>
                                }

                            </div>

                        ))}

                    </div>
                </form>
            </div>
        ));
    }, [numofinstrument, standards]);

    const instrumentCalibration = useMemo(() => {
        return [...Array(numofinstrument)].map((_, instrumentIndex) => (
            <div key={instrumentIndex}>
                <h4 className="card-title">Instrument #{instrumentIndex + 1} Calibration </h4>
                <form className='loginForm' style={{ height: 'fit-content' }}>
                    <div className="mb-3">
                        <Form.Group controlId={`input_label`}>
                            <Form.Label>Engineer Name</Form.Label>
                            <Form.Select
                                name="engineer"
                                value={formData[instrumentIndex]?.engineerid || ""}
                                onChange={e => handleEngineerList(e.target.value, instrumentIndex)}
                            >
                                <option>--select Engineer Name--</option>
                                {engineerList.map((list, index) => (
                                    <option
                                        key={index}
                                        value={list.user_id}
                                    >
                                        {list.first_name + " " + list.last_name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId={"input_label"}>
                            <Form.Label>Calibration Date</Form.Label>
                            <Form.Control
                                type="date"
                                defaultValue={formData[instrumentIndex]?.calibration_date?.split("T")[0]}
                                onChange={(e) => CertificateData(instrumentIndex, '', 'calibration_date', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId={"input_value"}>
                            <Form.Label>Calibration Due Date</Form.Label>
                            <Form.Control
                                type="date"
                                defaultValue={formData[instrumentIndex]?.calibration_due_date?.split("T")[0]}
                                onChange={(e) => CertificateData(instrumentIndex, "value", "calibration_due_date", e.target.value)}
                            />
                        </Form.Group>

                    </div>
                </form>
            </div>
        ));
    }, [numofinstrument, formData]);

    const instrumentTestConditions = useMemo(() => {
        return [...Array(numofinstrument)].map((_, instrumentIndex) => (
            <div key={instrumentIndex}>
                <h4 className="card-title">Instrument #{instrumentIndex + 1} Test Condition</h4>
                <form className='loginForm' style={{ height: 'fit-content' }}>
                    <div className="mb-3">
                        {testCondition?.map((standard, standardIndex) => (
                            <div key={standardIndex}>
                                {
                                    standardIndex === instrumentIndex ?
                                        <>
                                            {standard?.map((input, inputIndex) => (
                                                <div key={inputIndex}>
                                                    <Form.Group controlId={`input_label_${standardIndex}_${inputIndex}`}>
                                                        <Form.Label>Label</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={input?.label}
                                                            onChange={(e) => handleTestConditionInputChange(instrumentIndex, inputIndex, 'label', e.target.value)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId={`input_value_${standardIndex}_${inputIndex}`}>
                                                        <Form.Label>Value</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={input?.value}
                                                            onChange={(e) => handleTestConditionInputChange(instrumentIndex, inputIndex, 'value', e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            ))}
                                            <Button variant="primary" onClick={() => handletestConditionInput(instrumentIndex)}>
                                                Add Input
                                            </Button>
                                            <hr />
                                        </>
                                        : <></>
                                }
                            </div>
                        ))}

                    </div>
                </form>
            </div>
        ));
    }, [numofinstrument, testCondition]);

 

    return (<div className='create'>
            <Header>
                {
                    (type === "draft" && data?.length === 0) ? <Preloader /> :
                    previewState ? <Preview type={type} certificate={formData} /> :
                        <div className='container'>
                            <div className="row">
                                <div className="col-12">
                                    <h3 style={{ textTransform: "capitalize" }}>{type === "create" || type === "modify" ? "new certificate" : "edit draft"}</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-lg-12 col-md-12 mt-2'>
                                    <div className="buttonContainer">
                                        <button className="btn btn-primary"
                                            onClick={handleSubmit} disabled={processing}>
                                            {!processing ? "Save Draft" : "Processing Draft"}</button>
                                        <button className="btn btn-primary" onClick={previewCert}>Preview Certificate</button>
                                        <button className="btn btn-primary" variant="primary" onClick={handleShow}>Send For Approval</button>
                                        {type === "draft" ? <button disabled={deleteProcessing} onClick={handleDraftDelete} className="btn btn-secondary">{deleteProcessing ? "Deleting..." : "Remove Draft"}</button> : null}
                                    </div>
                                </div>
                                <div className={type === "draft" && formData[0]?.comments !== "" && formData[0]?.comments !== null ? 'col-lg-9' : 'col-lg-12'}>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12' style={blockDisplay === 0 ? { display: "block" } : { display: "none" }}>
                                            <div className='card' style={{ border: 0 }}>
                                                <div className="card-body">
                                                    <div className="card-top">
                                                        <h4 className="card-title">Customer Information</h4>
                                                        <button className="btn btn-primary" style={{ textTransform: "capitalize", backgroundColor: "#0a1f6a", borderColor: "#0a1f6a" }} onClick={handleCustomerModalShow}>add customer </button>
                                                    </div>
                                                    <div className='loginForm' style={{ height: 'fit-content' }}>
                                                        <div className="mb-3">
                                                            <label htmlFor="customername" className="form-label">Customer Name</label>
                                                            <Form.Group controlId="formTypeahead">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Name of Customer"
                                                                    value={inputValue}
                                                                    onChange={handleInputChange}
                                                                    onFocus={() => setShowDropdown(true)}
                                                                />
                                                                {showDropdown && filteredOptions.length > 0 && (
                                                                    <Dropdown className="mt-1" show={showDropdown}>
                                                                        <Dropdown.Menu>
                                                                            {filteredOptions.map((option, index) => (
                                                                                <Dropdown.Item
                                                                                    key={index}
                                                                                    onClick={() => handleSelectOption(option.company_id, option.company_name, option.address, option.state)}
                                                                                >
                                                                                    {option.company_name}
                                                                                </Dropdown.Item>
                                                                            ))}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                )}
                                                            </Form.Group>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="customeraddress" className="form-label">Enter Address</label>
                                                            <input type="text" className="form-control" id="customeraddress"
                                                                value={address}
                                                                onChange={e => setAddress(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="state" className="form-label">Enter State</label>
                                                            <input type="text" className="form-control" id="customerstate"
                                                                value={customerLocation}
                                                                onChange={e => setCustomerLocation(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="mb-3"  >
                                                            <label className="form-label">Number of Certificate to Create</label>
                                                            <input type="number" min="1" className="form-control" onChange={numberOfInstrument} />
                                                        </div>
                                                        {
                                                            inputValue && address && customerLocation && numofinstrument >= leastInstrumentNum && numofinstrument <= highestInstrumentNum &&
                                                            <button className="btn btn-primary" style={{ width: "20%" }} onClick={() => {
                                                                setBlockDisplay(1);
                                                                      for(let i = 0; i < numofinstrument; i++){
                                                                        CertificateData(i, "", "company_name", inputValue);
                                                                        CertificateData(i, "", "address", address);
                                                                        CertificateData(i, "", "location", customerLocation);
                                                                    }
                                                                }}>Next</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-md-12' style={blockDisplay === 1 ? { display: "block" } : { display: "none" }}>
                                            <div className='card' style={{ border: 0 }}>
                                                <div className="card-body">
                                                    {type === "create" || type === "modify" ?
                                                        <> <div className="card-nav" onClick={() => setBlockDisplay(0)}>
                                                            <FontAwesomeIcon icon="fa-solid fa-angles-left" />&nbsp;&nbsp;
                                                            <span>Previous</span>
                                                        </div>
                                                        </>
                                                        : <></>}
                                                    {instrumentDetails}
                                                </div>
                                            </div>
                                            <button className="btn btn-primary mt-2" style={{ width: "20%" }} onClick={() => setBlockDisplay(2)}>Next</button>
                                        </div>
                                        <div className='col-lg-12 col-md-12' style={blockDisplay === 2 ? { display: "block" } : { display: "none" }}>
                                            <div className='card' style={{ border: 0 }}>
                                                <div className="card-body">
                                                    <div className="card-nav" onClick={() => setBlockDisplay(1)}>
                                                        <FontAwesomeIcon icon="fa-solid fa-angles-left" />&nbsp;&nbsp;
                                                        <span>Previous</span>
                                                    </div>
                                                    {instrumentStandards}
                                                </div>
                                            </div>
                                            <button className="btn btn-primary mt-2" style={{ width: "20%" }} onClick={() => setBlockDisplay(3)}>Next</button>
                                        </div>
                                        <div className='col-lg-12 col-md-12' style={blockDisplay === 3 ? { display: "block" } : { display: "none" }}>
                                            <div className='card' style={{ border: 0 }}>
                                                <div className="card-body">
                                                    <div className="card-nav" onClick={() => setBlockDisplay(2)}>
                                                        <FontAwesomeIcon icon="fa-solid fa-angles-left" />&nbsp;&nbsp;
                                                        <span>Previous</span>
                                                    </div>
                                                    {instrumentCalibration}
                                                </div>
                                            </div>
                                            <button className="btn btn-primary mt-2" style={{ width: "20%" }} onClick={() => setBlockDisplay(4)}>Next</button>
                                        </div>
                                        <div className='col-lg-12 col-md-12' style={blockDisplay === 4 ? { display: "block" } : { display: "none" }}>
                                            <div className='card' style={{ border: 0 }}>
                                                <div className="card-body">
                                                    <div className="card-nav" onClick={() => setBlockDisplay(3)}>
                                                        <FontAwesomeIcon icon="fa-solid fa-angles-left" />&nbsp;&nbsp;
                                                        <span>Previous</span>
                                                    </div>
                                                    {instrumentTestConditions}
                                                </div>
                                            </div>
                                            <button className="btn btn-primary mt-2" style={{ width: "20%" }} onClick={() => setBlockDisplay(5)}>Next</button>
                                        </div>
                                        <div className='col-lg-12 col-md-12' style={blockDisplay === 5 ? { display: "block" } : { display: "none" }}>
                                            <div className="card-nav" onClick={() => setBlockDisplay(4)}>
                                                <FontAwesomeIcon icon="fa-solid fa-angles-left" />&nbsp;&nbsp;
                                                <span>Previous</span>
                                            </div>
                                            <h4>Calibration Data</h4>
                                            <div>

                                                {tables.map((table, tableIndex) => (
                                                    <div key={tableIndex}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Include Row Headers"
                                                            checked={includeHorizontalHeaders[tableIndex]?.[0]}
                                                            onChange={() => handleToggleHorizontalHeaders(tableIndex)}
                                                        />
                                                        <h4>Instrument #{tableIndex + 1} Calibration Data</h4>
                                                        <Form.Group controlId={`numColumns_${tableIndex}`}>
                                                            <Form.Label>Number of Columns</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={table.numColumns}
                                                                onChange={(e) => handleNumColumnsChange(tableIndex, e)}
                                                            />
                                                        </Form.Group>
                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    {includeHorizontalHeaders[tableIndex]?.[0] && <th>Row Headers</th>}
                                                                    {table.headers.map((header, index) => (
                                                                        <th key={index}>
                                                                            <Form.Control
                                                                                type="text"
                                                                                value={header}
                                                                                onChange={(e) => handleHeaderChange(tableIndex, index, e.target.value)}
                                                                            />
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {table.rows.map((row, rowIndex) => (
                                                                    <tr key={rowIndex}>
                                                                        {includeHorizontalHeaders[tableIndex]?.[0] && (<td>
                                                                            <Form.Control
                                                                                type="text"
                                                                                value={table.rowHeaders[rowIndex] || ''}
                                                                                onChange={(e) => handleRowHeaderChange(tableIndex, rowIndex, e.target.value)}
                                                                            />
                                                                        </td>)}
                                                                        {row.map((cell, colIndex) => (
                                                                            <td key={colIndex}>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    value={cell}
                                                                                    onChange={(e) => handleRowChange(tableIndex, rowIndex, colIndex, e.target.value)}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                        <td>
                                                                            <FontAwesomeIcon variant="danger" onClick={() => handleDeleteRow(tableIndex, rowIndex)} icon="fa-solid fa-trash" />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        <Button variant="primary" onClick={() => handleAddRow(tableIndex)}>
                                                            Add Row
                                                        </Button>
                                                    </div>
                                                ))}
                                            </div>


                                            <button className="btn btn-primary mt-2" style={{ width: "20%" }} onClick={() => setBlockDisplay(6)}>Next</button>
                                        </div>
                                        <div className='col-lg-12 col-md-12' style={blockDisplay === 6 ? { display: "block" } : { display: "none" }}>
                                            <div className='card' style={{ border: 0 }}>
                                                <div className="card-body">
                                                    <div className="card-nav" onClick={() => setBlockDisplay(5)}>
                                                        <FontAwesomeIcon icon="fa-solid fa-angles-left" />&nbsp;&nbsp;
                                                        <span>Previous</span>
                                                    </div>
                                                    {[...Array(numofinstrument)].map((_, index) => (
                                                        <div key={index}>
                                                            <h4 className="card-title">Instrument Comment #{index + 1}</h4>
                                                            <form className='loginForm' style={{ height: 'fit-content' }}>
                                                                <div className="mb-3">
                                                                    <label htmlFor="comment" className="form-label">Comment</label>
                                                                    <textarea className="form-control" id="comment" aria-describedby="comment"
                                                                        defaultValue={formData[index]?.staff_comment || ''}
                                                                        onChange={(e) => CertificateData(index, `instrument${index + 1}`, 'staff_comment', e.target.value)}
                                                                    ></textarea>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Select Engineer to Review Certificate</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Group controlId="formRole" className="mt-3">
                                                        <Form.Label>Engineer Name</Form.Label>
                                                        <Form.Select
                                                            name="role"
                                                            onChange={e => setHandleApprovalEngineerChange(e.target.value)}
                                                        >
                                                            <option value="">Select an engineer</option>
                                                            {
                                                                engineerList.map((list, index) => {
                                                                    return <option key={index} value={list.user_id}>{list.first_name + " " + list.last_name}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <Button variant="primary" type="submit" className="mt-3" disabled={processing}>
                                                        {processing ? "sending certificate" : "submit"}
                                                    </Button>
                                                </Form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Modal show={customerShow} onHide={handleCustomerModalClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Create New Customer</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form onSubmit={handleNewCustomer}>
                                                    <div className="mb-3">
                                                        <label htmlFor="customername" className="form-label">Customer Name</label>
                                                        <Form.Group controlId="formTypeahead">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Name of Customer"
                                                                value={customerName}
                                                                onChange={e => setCustomerName(e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="customercode" className="form-label">Enter Customer Code</label>
                                                        <input type="text" className="form-control" id="customercode"
                                                            value={customerCode}
                                                            onChange={e => setCustomerCode(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="customeraddress" className="form-label">Enter Address</label>
                                                        <input type="text" className="form-control" id="customeraddress"
                                                            value={address}
                                                            onChange={e => setAddress(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="state" className="form-label">Enter State</label>
                                                        <input type="text" className="form-control" id="customerstate"
                                                            value={customerLocation}
                                                            onChange={e => setCustomerLocation(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="country" className="form-label">Enter Country</label>
                                                        <input type="text" className="form-control" id="customercountry"
                                                            value={country}
                                                            onChange={e => setCountry(e.target.value)}
                                                        />
                                                    </div>
                                                    <Button variant="primary" type="submit" className="mt-3" disabled={processing}>
                                                        {processing ? "creating customer" : "submit"}
                                                    </Button>
                                                </Form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleCustomerModalClose}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                            <div className='col-lg-12 col-md-12 mt-2'>
                    <div className="buttonContainer">
                        <button className="btn btn-primary"
                            onClick={handleSubmit} disabled={processing}>
                            {!processing ? "Save Draft" : "Processing Draft"}</button>
                        <button className="btn btn-primary" onClick={previewCert}>Preview Certificate</button>
                        <button className="btn btn-primary" variant="primary" onClick={handleShow}>Send For Approval</button>
                        {type === "draft" ? <button disabled={deleteProcessing} onClick={handleDraftDelete} className="btn btn-secondary">{deleteProcessing ? "Deleting..." : "Remove Draft"}</button> : null}

                    </div>
                </div>
                                    </div>
                                </div>
                                {
                                    type === "draft" && formData[0]?.comments !== "" && formData[0]?.comments !== null ?
                                        <div className="col-lg-3">
                                            <h4>Reviewer Comment</h4>
                                            <div className="" dangerouslySetInnerHTML={{ __html: formData[0]?.comments }} />
                                        </div>
                                        : <></>
                                }
                            </div>
                        </div>
                }
            </Header>
        </div>)
}
export default CreateFunc;