import React, { useEffect, useState } from 'react';
import Header from '../../component/header';
import instance from "../../axios"
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import {Preview} from "../../component/preview";
import TableComp from '../../component/table/table';
import Preloader from "../../component/Preloader";
import {useStateValue} from "../../state/stateprovider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip';


const Approval = () => {
    const [{level, previewState, authid}, dispatch] = useStateValue();
    const Swal = swal;
    // const authid = window.localStorage.getItem("authid");
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [certificateInfo, setCertificateInfo] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(level === 'admin' || level === 'moderator' || level === 'technical'){
            fetchData();
          }else{
            navigate("/dashboard", {replace: true});
          }
    }, []);

    const fetchData = async () => {
        try {
            const approvalResponse = await instance.get(`/approval?authid=${authid}`);
            if (approvalResponse.data.status === "success") {
                const combine = Array.from(new Set(approvalResponse.data.result?.map(num => num.collectionid))).map(collectionid => {
                    const entry = approvalResponse.data.result.find(item => item.collectionid === collectionid);
                    return entry;
                });
                setList(combine);
                setLoading(false); // Update loading state
            } else {
                setLoading(false); // Update loading state
                setError("Failed to fetch data"); // Set error message
            }
        } catch (error) {
            setLoading(false); // Update loading state
            setError(error?.data?.message); // Set error message
        }
    };


    const handlePreview = async (collectionid) => {
        try {
            const certificateResponse = await instance.get(`/certificate?collectionid=${collectionid}&authid=${authid}`);
            if (certificateResponse.data.status === "success") {
                setCertificateInfo(certificateResponse.data.result);
                dispatch({
                    type: "CERT_PREVIEW",
                    previewState: true
                });
            } else if (certificateResponse.data.status === "invalid") {
                window.localStorage.clear();
                navigate("/", {replace: true});
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                text: error?.data?.message
            });
        }
    };

    const handleSubmit = async (collectionId) => {
        setProcessing(true);
        try {
            const response = await instance.post("/new-certificate", {
                authid: authid,
                status: "completed",
                collectionId: collectionId
            });
            if (response.data.status === "success") {
                setProcessing(false);
                Swal.fire({
                    icon: "success",
                    text: "Certificate successfully created"
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Couldn't create certificate(s)"
                });
                setProcessing(false);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                text: error.message
            });
            setProcessing(false);
        }
    };

    const handleDraft = async (collectionid) => {
        setProcessing(true);
        try {
            const response = await instance.put("/draft/return", {
                authid: authid,
                collectionId: collectionid
            });
            if (response.data.status === "success") {
                setProcessing(false);
                Swal.fire({
                    icon: "success",
                    text: "Certificate returned to draft"
                });
                window.location.reload();
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Couldn't return certificate(s) to draft"
                });
                setProcessing(false);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                text: error.data.message
            });
            setProcessing(false);
        }
    };

    return (
        <>
            {previewState
                ? <Preview type="draft"  certificate={certificateInfo} />
                : <div className='approval-list'>
                    <Header>
                    <div className='container'>
                        <div className='row'>
                            {loading ? (
                                <Preloader />
                            ) : error ? (
                                <p>{error}</p>
                            ) : list.length === 0 ? (
                                <p>No items to display</p>
                            ) : (

                                <>
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                             
                                </div>
                                <TableComp title="Certificate to Approve" data={list.map((item, index) => {
                                    return {"S/N": index + 1, "Name of Company": item.company_name, "Staff Name": item.first_name + " " + item.last_name, "Action": <>
                                        <FontAwesomeIcon className={'icon-preview'} icon="fa-solid fa-eye" onClick={() => handlePreview(item.collectionid)} />
                                        <Tooltip anchorSelect={'.icon-preview'} place="right">
                                        preview
                                    </Tooltip>
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon className={'icon-share'} icon="fa-solid fa-share-from-square" onClick={() => handleDraft(item.collectionid)} />
                                        <Tooltip anchorSelect={'.icon-share'} place="right">
                                        return to draft
                                    </Tooltip>
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon className={'icon-check'} icon="fa-solid fa-square-check" onClick={() => handleSubmit(item.collectionid)} />
                                        <Tooltip anchorSelect={'.icon-check'} place="right">
                                        mark completed
                                    </Tooltip>
                                        &nbsp;&nbsp;
                                        </>}
                                })} />
                               

                                </>
                            )}
                        </div>
                    </div>
                    </Header>
                </div>
            }
        </>
    );
}

export default Approval;
