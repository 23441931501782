import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import instance from "../../axios";
import swal from 'sweetalert2';
import { useStateValue } from '../../state/stateprovider';

function ConfirmEmail() {
  const [{}, dispatch] = useStateValue();
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState(false);
  const Swal = swal;
  const navigate = useNavigate();


  const handleForm = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await instance({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: { email: email.trim() },
        url: `/reset/newtoken`
      });
      setProcessing(false);
      switch (response.data.status) {
        case "success":
          dispatch({type:"RESET_PASS", resetEmail: response.data.email })
          navigate("/reset-password/token");
          break;
        case "no_account":
          Swal.fire({
            title: "You don't have an account with us",
            button: "Ok"
          });
          navigate("/", {replace: true});
          break;
        case "error":
          Swal.fire({
            icon: "error",
            title: "An error has occured. Try later",
            button: "Ok"
          });
          break;
        default:
          break;
      }
    } catch (err) {
      setProcessing(false);
      if(err.response?.data?.status){
        Swal.fire({
          icon: "error",
          title: err.response?.data?.message,
          button: "Ok"
        });
      }else{
        Swal.fire({
          icon: "error",
          title: "An error occurred"
        });
      }
    }
  };


  return (
      <div className="container" style={{height: 100 + "vh"}}>
        <div className="row" style={{ margin: "2.5rem"}}>
        <div className='col-sm-12 col-lg-12 mb-3'>
            <h3>Reset Your Password</h3>
        </div>
          <div className="col-sm-12 col-lg-12 d-lg-block">
            <form className="loginForm" onSubmit={handleForm}>
              <div className="mb-3">
                <label htmlFor="inputEmail" className="form-label"><b>Enter your Email address</b></label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  style={{height: 60 + "px", width: 70 + "%"}}
                />
              </div>
              <div className="mb-3">
              <button type="submit" disabled={processing} style={processing ? {cursor: "not-allowed", fontWeight: 600, width: "30% !important"} : {cursor: "pointer", fontWeight: 600, width: "30% !important"}} className="btn btn-primary">{processing ? "Processing" : "Submit"}</button>
                </div>

              <span>Code will be sent to registered email address</span>
            </form>
          </div>
        </div>
      </div>
  );
}

export default ConfirmEmail;
