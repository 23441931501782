
export const initialState = {
  previewState: false,
  inputValues: Array(6).fill(""),
  focusedIndex: 0,
  status: "idle",
  resetEmail: "",
  level: window.localStorage.getItem("level"),
  authid: window.localStorage.getItem("authid"),
  name: null,
  formDataState: Array.from({ length: 1 }, () => ({})),
  blockDisplayState: 0
};

export function reducer(state, action) {
  const clampIndex = (index) => Math.min(Math.max(index, 0), state.inputValues.length - 1);
  switch (action.type) {
    case "LEVEL":
      return {
        ...state,
        level: action.level,
      };
      case "CERTIFICATE_DATA":
      return {
        ...state,
        formDataState: action.formDataState,
      };
      case "CREATE_CERT_BLOCK":
        return {
          ...state,
          blockDisplayState: action.blockDisplayState,
        };
    case "AUTH":
      return {
        ...state,
        authid: action.authid,
      };
    case "NAME":
      return {
        ...state,
        name: action.name,
      };
    case "RESET_PASS":
      return {
        ...state,
        resetEmail: action.resetEmail,
      };
    case "CERT_PREVIEW":
      return {
        ...state,
        previewState: action.previewState,
      };
    case "INPUT":
      return {
        ...state,
        inputValues: [
          ...state.inputValues.slice(0, action.payload.index),
          action.payload.value,
          ...state.inputValues.slice(action.payload.index + 1),
        ],
        focusedIndex: clampIndex(state.focusedIndex + 1),
      };

    case "BACK":
      return {
        ...state,
        focusedIndex: clampIndex(state.focusedIndex - 1),
      };

    case "PASTE":
      const splitPasted = action.payload.pastedValue[0].split("")
      return {
        ...state,
        inputValues: state.inputValues.map(
          (_, index) => splitPasted[index]
        ),
      };

    case "FOCUS":
      return {
        ...state,
        focusedIndex: action.payload.focusedIndex,
      };

    case "VERIFY":
      return {
        ...state,
        status: "pending",
      };

    case "VERIFY_SUCCESS":
      return {
        ...state,
        status: "idle",
        inputValues: Array(6).fill(""),
        focusedIndex: 0
      };

    default:
      return state;
  }
}