import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const MultiFormCreator = () => {
  const [numForms, setNumForms] = useState(1);
  const [formData, setFormData] = useState(Array.from({ length: numForms }, () => ({})));

  const handleChange = (index, key, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [key]: value
    };
    setFormData(updatedFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
  };

  const handleNumFormsChange = (event) => {
    const num = parseInt(event.target.value) || 1;
    setNumForms(num);
    setFormData(Array.from({ length: num }, () => ({})));
  };

  return (
    <div>
      <h1>Multi-Form Creator</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="numForms">
          <Form.Label>Number of Forms</Form.Label>
          <Form.Control type="number"  onChange={handleNumFormsChange} />
        </Form.Group>
        {[...Array(numForms)].map((_, index) => (
          <div key={index}>
            <Form.Group controlId={`name_${index}`}>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" value={formData[index].name || ''} onChange={(e) => handleChange(index, 'name', e.target.value)} />
            </Form.Group>
            <Form.Group controlId={`email_${index}`}>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={formData[index].email || ''} onChange={(e) => handleChange(index, 'email', e.target.value)} />
            </Form.Group>
            <hr />
          </div>
        ))}
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default MultiFormCreator;
