import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import instance from "../axios";
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert2';
import Header from "../component/header";
import TableComp from '../component/table/table';
import Preloader from "../component/Preloader";
import {useStateValue} from "../state/stateprovider";


function Activity() {
  const [userActivity, setUserActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const Swal = swal;
  const navigate = useNavigate();
  const [{level, authid}] = useStateValue();


  useEffect(() => {
    if(level !== 'admin'){
      navigate("/dashboard", {replace: true});
    }
    
    instance({
      method: "GET",
      headers: { "Content-Type": "application/json" },
      url: `/staff/activity?authid=${authid}`
    }).then(response => {
      setLoading(false)
      if (response.data.status === "success") {
        setUserActivity(response.data.result)
      }else if(response.data.status === "invalid"){
        window.localStorage.clear();
        navigate("/", {replace: true});
      } else {
        Swal.fire({
          icon: "error",
          title: "An error occurred"
        }).then(() => {
          navigate("/dashboard");
        })
      }
    }).catch(err => {
      setLoading(false)
      Swal.fire({
        icon: "error",
        title: "An error occurred"
      }).then(() => {
        navigate("/dashboard");
      })
    })
  }, [])
  return (
    <Header>
      {
        loading ? <Preloader /> : <>
          {
            userActivity.length === 0 ? "no activities yet" :
              <Container>
                <Row>
                  <Col>
                    <h2>User Activities</h2>
                    <TableComp title="Events" data= {userActivity.map((activity, index) => ({
                      "S/N": index + 1,
                      "Staff Name": activity.first_name + " " + activity.last_name,
                      "Event": activity.activity,
                      "Time": new Date(activity.datecreated).toString()
                    }
                    ))} />
                   {/* <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Name</th>
                          <th>Action</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userActivity.map((activity, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{activity.first_name + " " + activity.last_name}</td>
                            <td>{activity.activity}</td>
                            <td>{new Date(activity.datecreated).toString()}</td>
                          </tr>
                        ))}

                      </tbody>
                    </Table>*/}
                  </Col>
                </Row>
              </Container>
          }
        </>
      }
    </Header>
  );
}

export default Activity;
