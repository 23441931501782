import React, { useState, useCallback, useEffect } from 'react';
import { PDFViewer, Page, Image, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PDFTable from './PDFTable';
import tangerineRegular from '../asset/font/tangerine/Tangerine-Regular.ttf';
import tangerineBold from '../asset/font/tangerine/Tangerine-Bold.ttf';
import robotoRegular from '../asset/font/Roboto/Roboto-Regular.ttf';
import robotoBold from '../asset/font/Roboto/Roboto-Bold.ttf';
import robotoMedium from '../asset/font/Roboto/Roboto-Medium.ttf';
import robotoThin from '../asset/font/Roboto/Roboto-Thin.ttf';
import robotoItalic from '../asset/font/Roboto/Roboto-Italic.ttf';
import logo from "../asset/image/logo.png";
import instance from "../axios";
import swal from 'sweetalert2';
import {downloadPdf, QrCodeGenerator} from "../controller/index";
import Preloader from "./Preloader";
import { useStateValue } from "../state/stateprovider";
import { useNavigate } from 'react-router-dom';




Font.register({
    family: 'tangerineRegular',
    src: tangerineRegular,
});
Font.register({
    family: 'tangerineBold',
    src: tangerineBold,
});
Font.register({
    family: 'robotoRegular',
    src: robotoRegular,
});
Font.register({
    family: 'robotoBold',
    src: robotoBold,
});
Font.register({
    family: 'robotoMedium',
    src: robotoMedium,
});
Font.register({
    family: 'robotoThin',
    src: robotoThin
});
Font.register({
    family: 'corsiva',
    src: robotoItalic
})

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    section: {
        width: '100%'
    },
    Preview: {
        marginLeft: 30,
        marginRight: 30,
        paddingLeft: 20,
        paddingRight: 20,
    },
    previewLogo: {
        width: 300,
        height: 150,
        objectFit: 'contain',
        position:  "absolute",
        left: 5
    },
    headSection: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingLeft: 50,
        paddingRight: 50,
        width: '100%',
        marginTop: 50,
    },
    topText: {
        // width: '100%',
        alignItems: 'flex-end',
        flexDirection: 'column',
        color: '#0a1f6a',
        fontFamily: 'tangerineRegular',
        fontWeight: 700,
        fontSize: 16,
    },
    sectionOne: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        paddingLeft: 50,
        paddingRight: 50,
        marginBottom: 10,
        marginTop: 10
    },
    sectionTwo: {
        width: '100%',
        paddingLeft: 50,
        paddingRight: 50,
        marginBottom: 10,
    },
    sectionbody: {
        width: '100%',
        marginBottom: 10,
    },
    instruemntIdBody: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    standardContainer: {
        width: '100%',
        flexDirection: 'column',
        height: 'auto',
        justifyContent: 'space-between'
    },
    standardMainBody: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    standardBody: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    standard: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    comment: {
        width: '100%',
        textAlign: 'justify',
    },
    signature: {
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center"
    },
    signatureBody: {
        margin: 0,
        flexDirection: "column",
        alignItems: "center"
    },
    signatureImage: {
        width: 100,
        height: 50,
        objectFit: "cover",
    },
    bolderText: {
        fontFamily: 'robotoBold',
        fontSize: 18
    },
    boldText: {
        fontFamily: 'robotoMedium',
        fontSize: 14
    },
    standardBoldText: {
        fontSize: 12,
        fontWeight: 500,
        fontFamily: 'robotoRegular',
    },
    regularText: {
        fontSize: 12,
        fontFamily: 'robotoRegular',
    },
    smallText: {
        fontFamily: 'robotoThin',
        fontSize: 10
    },
    watermark: {
        position: 'absolute',
        top: '50%',
        left: '40%',
        transformOrigin: 'top left',
        transform: 'translate(-30%, -30%) rotate(-45deg)',
        opacity: '0.5',
        fontSize: 80,
        color: '#3f48cc',
        textTransform: 'uppercase',
        fontFamily: 'robotoBold',
        letterSpacing: 5,
        zIndex: 999
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100vh'
    },
    sidebar: {
        width: '25%',
        padding: '10px',
        boxSizing: 'border-box',
        borderRight: '1px solid #ccc',
        backgroundColor: '#f9f9f9'
    },
    pdfViewer: {
        width: '75%',
        height: '100vh'
    },
    label: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '5px'
    },
    sidebarButton: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        height: 50,
        alignItems: "center",
        borderBottom: "1px solid #000"
    },
    qrImage: {
        width: 50,  // Set the size of the QR code in the PDF
        height: 50,
    }
});

async function qrCode(certificate_no) {
    try {
      const result = await QrCodeGenerator(certificate_no);
        return result;
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

export const PdfDocument = React.memo(({ type, certificate }) => (
    <Document style={styles.Preview}>
        {certificate.map((cert, index) => (
            <Page size="A4" style={styles.page} key={index}>
                {cert.statusname === "completed" && type === "completed" ? null : (
                    <View style={styles.watermark}>
                        <Text>Draft</Text>
                    </View>
                )}
                <View style={styles.headSection}>
                    <Image src={logo} alt="AAS Logo" style={styles.previewLogo} />
                    <View style={styles.topText}>
                        <Text>22, Bolaji Benson Street,</Text>
                        <Text>Ikorodu, Lagos</Text>
                        <Text><Text style={{ marginRight: 12 }}>Tel:</Text>07084594001, 07084594004</Text>
                        <Text><Text style={{ paddingRight: 12 }}>Email:</Text>aas@aasnig.com</Text>
                        <Text><Text style={{ paddingRight: 12 }}>Website:</Text> https://www.aasnig.com</Text>
                    </View>
                </View>

                <View style={styles.sectionOne}>
                    <View style={styles.section}>
                        <Text style={[styles.bolderText, { marginBottom: 10 }]}>Certificate of Calibration</Text>
                        <Text style={styles.boldText}>Customer Information</Text>
                        <Text style={styles.regularText}>{cert.company_name}</Text>
                        <Text style={styles.regularText}>{cert.address}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.boldText, { textAlign: 'center' }]}>No: {cert.certificate_no || "N/A"}</Text>
                    </View>
                </View>

                <View style={styles.sectionTwo}>
                    <View style={styles.sectionbody}>
                        <Text style={styles.boldText}>Instrument Identification</Text>
                        <View style={styles.instruemntIdBody}>
                            <Text style={styles.regularText}><Text style={{ paddingRight: 12 }}>Description:</Text> {cert?.instrument_id?.instrumentdescription || "N/A"}</Text>
                            <Text style={styles.regularText}><Text style={{ paddingRight: 12 }}>Model:</Text> {cert?.instrument_id?.instrumentmodel || "N/A"}</Text>
                            <Text style={styles.regularText}><Text style={{ paddingRight: 12 }}>S/N:</Text> {cert?.instrument_id?.instrumentserial || "N/A"}</Text>
                        </View>
                    </View>

                    <View style={styles.sectionbody}>
                        <Text style={styles.boldText}>Standards/Equipment Used</Text>
                        <View style={styles.standardContainer}>
                            {cert?.standard ? cert?.standard?.map((stand, index) => (
                                <View style={styles.standardMainBody} key={index}>
                                    {Object.keys(stand).map((key, innerIndex) => (
                                        <View style={styles.standardBody} key={innerIndex}>
                                            <View style={styles.standard}>
                                                <Text style={[styles.standardBoldText, { textTransform: 'capitalize',  }]}>{key}</Text>
                                                <Text style={styles.regularText}>{stand[key]}</Text>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                            )) : <Text style={styles.regularText}>N/A</Text>}
                        </View>
                    </View>

                    <View style={styles.sectionbody}>
                        <Text style={styles.boldText}>Certificate Information</Text>
                        <View style={styles.instruemntIdBody}>
                            <Text style={[styles.regularText, { textTransform: 'capitalize' }]}><Text>Engineer:</Text> {(cert?.last_name + " " + cert?.first_name) || "N/A"}</Text>
                            <Text style={styles.regularText}><Text>Calibration Date:</Text> {formatDate(cert.calibration_date?.split("T")[0]) || formatDate(cert?.calibration_date) || "N/A"}</Text>
                            <Text style={styles.regularText}><Text>Calibration Due:</Text> {formatDate(cert.calibration_due_date?.split("T")[0]) || formatDate(cert?.calibration_due_date) || "N/A"}</Text>
                        </View>
                    </View>

                    <View style={styles.sectionbody}>
                        <Text style={styles.boldText}>Test Conditions</Text>
                        <View>
                            {cert?.conditions ? Object.keys(cert.conditions).map((condition, index) => (
                                <Text style={styles.regularText} key={index}>
                                    <Text style={{ textTransform: 'capitalize' }}>{condition}:</Text> {cert.conditions[condition]}
                                </Text>
                            )) : <Text style={styles.regularText}>N/A</Text>}
                        </View>
                    </View>

                    <View style={styles.sectionbody}>
                        <Text style={styles.boldText}>Calibrated Data</Text>
                        {cert?.calibration_data ? (
                            <PDFTable
                                headers={cert?.calibration_data?.headers?.filter(head => head !== "") || []}
                                data={cert?.calibration_data?.rows?.map(row => row?.filter(item => item !== "")) || []}
                                rowHeaders={ cert?.calibration_data?.rowHeaders?.filter(rowHead => rowHead !== "") || []}
                            />
                        ) : <Text style={styles.regularText}>N/A</Text>}
                    </View>
                    <View style={styles.sectionbody}>
                        <Text style={[styles.regularText, styles.comment]}>
                            {cert?.staff_comment ? <Text>{cert?.staff_comment}</Text> : "N/A"}
                        </Text>
                    </View>

                    <View style={[styles.sectionbody, {marginTop: "20"}]}>
                        <View style={styles.signature}>
                            <View style={styles.signatureBody}>
                                {(cert?.creatorsignature && <Image src={cert?.creatorsignature.fileBuffer} alt="signature" style={styles.signatureImage} />) || <Text style={[styles.smallText, styles.signatureImage, {textAlign: "center"}]}> No Signature </Text>}
                                {cert?.creatorfirst_name ? <Text style={[styles.regularText, { textTransform: "capitalize" }]}>{cert?.creatorfirst_name + " " + cert.creatorlastname}</Text> : "N/A"}
                                <Text style={[styles.standardBoldText, { textTransform: "capitalize" }]}>Issuing Engineer</Text>
                            </View>
                            <View style={styles.signatureBody}>
                                {(cert?.reviewersignature && <Image src={ cert?.reviewersignature.fileBuffer} alt="signature" style={styles.signatureImage} />) || <Text style={[styles.smallText, styles.signatureImage, {textAlign: "center"}]}> No Signature </Text>}
                               {cert?.reviewerfirst_name ? <Text style={[styles.regularText, { textTransform: "capitalize" }]}>{cert?.reviewerfirst_name + " " + cert?.reviewerlastname}</Text> : "N/A"}
                                <Text style={[styles.standardBoldText, { textTransform: "capitalize" }]}>Approving Engineer</Text>
                            </View>
                         
                        </View>
                    </View>
                    {cert.statusname === "completed" && type === "completed" && (<View style={[styles.sectionbody, { marginTop: "5" }]}>
                        <Image src={qrCode(cert?.certificate_no)} style={styles.qrImage} />
                    </View>
                    )}
                </View>
            </Page>
        ))}
    </Document>
));

export const Preview = ({ type, certificate }) => {
    const [comments, setComments] = useState('');
    const [commentState, setCommentState] = useState(false);
    const [show, setShow] = useState(false);
    const [ {authid}, dispatch] = useStateValue();
    const [processing, setProcessing] = useState(false);
    const [certificateData, setCertificateDate] = useState(type === "bulk" ? certificate[0].certificateData : certificate);

    const updateAfterSeconds = 5;

    // const authid = window.localStorage.getItem("authid");
    const Swal = swal;
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, updateAfterSeconds * 1000);

        return () => clearTimeout(timer);
    }, [])
    const handleCommentChange = useCallback((value) => {
        setComments(value);
    }, []);


    const handleSubmit = useCallback((collectionId) => {
        setCommentState(true);
        instance({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: `/create/comment`,
            data: { collectionId: collectionId, authid, comment: comments, reviewEngineerId: certificate[0].reviewengineerid }
        }).then(response => {
            setCommentState(false);
            if (response.data.status === "success") {
                Swal.fire({
                    icon: "success",
                    text: "Comment saved!"
                });
            }
        }).catch(err => {
            setCommentState(false);
            Swal.fire({
                icon: "error",
                text: err.message
            });
        });
    }, [authid, comments]);

    const handleCreateCertificate = () => {
        setProcessing(true);
        instance({
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: { authid: authid, certificateData: certificateData, customername: certificate[0].customername, address: certificate[0].address, location: certificate[0].location, status: certificate[0].status },
          url: `/new-certificate`
        }).then(response => {
          if (response.data.status === "success") {
            setProcessing(false);
            Swal.fire({
              icon: "success",
              text: `successfully drafted ${certificateData?.length} new certificate(s)`
            }).then(() => {
                dispatch({
                    type: "CERT_PREVIEW",
                    previewState: false
                });
              navigate("/draft");
            })
          } else {
            Swal.fire({
              icon: "error",
              text: "Couldn't  create certificate(s)"
            });
            setProcessing(false);
          }
        }).catch(err => {
          Swal.fire({
            icon: "error",
            text: err
          })
          setProcessing(false);
        });
      };
 
    return (
        <div style={styles.container}>
            {certificateData[0].statusname === "review" ? (
                <div style={styles.sidebar}>
                    <div style={styles.sidebarButton}>
                    <button className="btn btn-primary" onClick={() => {
                        dispatch({
                            type: "CERT_PREVIEW",
                            previewState: false
                        });
                    }}>back</button>
                    <button className="btn btn-secondary" onClick={() => downloadPdf(certificate, type)}>download</button>
                    </div>
                   
                    <label style={styles.label}>Comments:</label>
                    <button className="btn btn-secondary" onClick={() => handleSubmit(certificate[0].collectionid)}>{commentState ? 'Processing comment' : 'Save Comment'}</button>
                    <ReactQuill
                        value={comments ? comments : certificate[0].comments}
                        onChange={handleCommentChange}
                        placeholder="Enter your comments here"
                        style={{ height: '80%', marginBottom: '20px' }}
                    />
                </div>
            ) : <div style={styles.sidebar}>
            <div style={styles.sidebarButton}>
            <button className="btn btn-primary" onClick={() => {
                dispatch({
                    type: "CERT_PREVIEW",
                    previewState: false
                });
            }}>back</button>
            {
                type === "bulk" ? <button className="btn btn-secondary" disabled={processing} onClick={() => handleCreateCertificate()}>{processing ? "processing draft certificates" :"Submit"}</button>
                :             <button className="btn btn-secondary" onClick={() => downloadPdf(certificateData, type)}>download</button>


            }
            </div>
            </div>}
            {
                show ? <PDFViewer style={styles.pdfViewer}>
                    <PdfDocument type={type} certificate={certificateData} comments={comments} />
                </PDFViewer> : <Preloader />
            }

        </div>
    );
};


